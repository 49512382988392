@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-raleway: Raleway;
  --font-poppins: Poppins;
  --text-sm-font-normal: Inter;

  /* font sizes */
  --font-size-5xl: 24px;
  --font-size-xl: 20px;
  --font-size-xs: 12px;
  --font-size-lgi: 19px;
  --font-size-sm: 14px;
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --text-sm-font-normal-size: 18px;

  /* Colors */
  --neutrals-cool-gray-800: #1e293b;
  --mec-primary-rich-black: #050d15;
  --color-gray-100: rgba(30, 41, 59, 0.5);
  --color-gray-200: rgba(255, 255, 255, 0.08);
  --color-gray-300: rgba(255, 255, 255, 0.8);
  --color-gray-400: rgba(255, 255, 255, 0.1);
  --color-gray-500: rgba(255, 255, 255, 0.7);
  --color-gray-600: rgba(5, 13, 21, 0.5);
  --color-gray-700: rgba(255, 255, 255, 0.4);
  --color-gray-800: rgba(5, 13, 21, 0.4);
  --color-gray-900: rgba(255, 255, 255, 0.3);
  --color-gray-1000: rgba(255, 255, 255, 0.05);
  --mec-primary-mec-light-gradient: #6a00f4;
  --basic-white: #fff;
  --mec-primary-cornflower-blue: #5591f5;
  --neutrals-cool-gray-600: #475569;
  --color-darkslategray-100: rgba(56, 64, 71, 0.4);
  --color-darkslategray-200: rgba(56, 64, 71, 0.09);
  --neutrals-cool-gray-500: #64748b;
  --mec-primary-mec-dark-gradient1: #6200ea;
  --primary-purple-50: #f6f0ff;

  /* Gaps */
  --gap-31xl: 50px;
  --gap-28xl: 47px;
  --gap-23xl: 42px;
  --gap-9xl: 28px;
  --gap-7xl: 26px;
  --gap-6xl: 25px;
  --gap-4xl: 23px;
  --gap-3xl: 22px;
  --gap-2xl: 21px;
  --gap-xl: 20px;
  --gap-lgi: 19px;
  --gap-mini: 15px;
  --gap-2xs: 11px;
  --gap-5xs: 8px;
  --gap-4xs: 9px;

  /* Paddings */
  --padding-36xl: 55px;
  --padding-23xl: 42px;
  --padding-22xl: 41px;
  --padding-17xl: 36px;
  --padding-18xl: 37px;
  --padding-11xl: 30px;
  --padding-9xl: 28px;
  --padding-8xl: 27px;
  --padding-6xl: 25px;
  --padding-5xl: 24px;
  --padding-4xl: 23px;
  --padding-2xl: 21px;
  --padding-xl: 20px;
  --padding-mid: 17px;
  --padding-mini: 15px;
  --padding-sm: 14px;
  --padding-smi: 13px;
  --padding-xs: 12px;
  --padding-2xs: 11px;
  --padding-3xs: 10px;
  --padding-4xs: 9px;
  --padding-5xs: 8px;
  --padding-6xs: 7px;
  --padding-7xs: 6px;
  --padding-8xs: 5px;
  --padding-9xs: 4px;
  --padding-10xs: 3px;
  --padding-10xs-5: 2.5px;
  --padding-11xs: 2px;
  --padding-12xs: 1px;

  /* Border radiuses */
  --br-330xl: 349px;
  --br-41xl: 60px;
  --br-31xl: 50px;
  --br-5xl: 24px;
  --br-xl: 20px;
  --br-5xs: 8px;

  /* Effects */
  --mec-boxes: 0px 4px 8px rgba(0, 0, 0, 0.25);
}
