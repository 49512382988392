.faqBg {
    margin: auto;
    background-image: url('../../assets/faqBg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.faq-content-container h2 {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 40px;
}

.faq-content-container,
.faq-wrapper,
.faq-content-wrapper,
.faq-content,
.faq-question-wrapper,
.faq-question,
.contact-container,
.contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.faq-content-container {
    align-items: flex-start;
}

.faq-content-wrapper {
    height: 472px;
}

.faq-content {
    margin: 20px 0;
}

.faq-question {
    flex-direction: row;
    justify-content: space-between;
}

.faq-question p:nth-child(1) {
    width: 90%;
    font-weight: 600;
}

.faq-arrow,
.faq-arrow-active {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #212a31;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
}

.faq-arrow-active {
    background-image: linear-gradient(90deg, #6200EA, #26C5F3);
    box-shadow: 0 0 5px 1px #6200EA;
}

.gradient-border {
    width: 100%;
    display: block;
    height: 1px;
    margin-top: 10px;
    background-image: linear-gradient(90deg, #26C5F3, #26C5F315);
}

.faq-answer,
.faq-answer-active {
    width: 95%;
    align-self: flex-end;
    font-weight: 300;
    font-size: 1rem;
    line-height: 20px;
    padding: 20px 0 0;
    transition: all 0.5s ease-in-out;
}

.faq-answer {
    display: none;
}

.faqNumber {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70px;
}

.faq-pageNo,
.faq-pageNo-active {
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff70;
    font-family: "Poppins", sans-serif;
    cursor: default;
}

.faq-pageNo-active {
    color: #ffffff;
    border-bottom: 1px solid #26C5F3;
}

.contact-container {
    align-items: flex-start;
    margin: 50px 0;
    scroll-margin-top: 150px;
}

.contact-heading h2:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 700;
}

.contact-heading h2:nth-child(2) {
    font-size: 2rem;
    font-weight: 900;
}

.contact-form {
    width: 100%;
    margin: 30px 0;
    max-width: 413px;
    align-self: flex-start;
}

.contact-input-wrapper {
    width: 100%;
}

.contact-input-wrapper:not(:last-of-type) {
    margin: 0 0 20px 0;
}

.contact-label {
    width: 100%;
    display: block;
    font-weight: 700;
}

.contact-input {
    width: 100%;
    color: #fff;
    background-color: white;
    background-color: #050d1550;
    outline: 0;
    padding: 14px 20px;
    border: .5px solid #ffffff08;
    border-radius: 20px;
    margin-top: 20px;
    font-weight: 500;
    font-size: 0.875rem;
    transition: all -.5s ease-in-out;
}

.contact-input::placeholder {
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-weight: 200;
    line-height: 30px;
    color: #ffffff;
}

.contact-input:focus {
    border: .9px solid #ffffff;
}

.error-message,
.error-message-active {
    color: #F87171;
    display: none;
    opacity: 0;
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
    font-size: 14px;
}

.error-message-active {
    display: block;
    opacity: 1;
}

.error-label-active {
    color: #F87171;
}

.error-style-active {
    border: .5px solid #F87171;
}
.error-label-active .contact-input{
    border: .5px solid #F87171;
}


@media screen and (min-width: 768px) {

    .faq-content-container h2:nth-child(1),
    .contact-heading h2:nth-child(2) {
        font-size: 2.3rem;
    }
}

@media screen and (min-width: 1024px) {
    .faqBg {
        width: 50%;
    }

    .faq-wrapper {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin: 50px 0;
    }

    .faq-content-container,
    .contact-container {
        width: 50%;
    }

    .faq-content-container h2:nth-child(1),
    .contact-heading h2:nth-child(2) {
        font-size: 2.5rem;
    }

    .contact-container {
        margin: 0;
        max-width: 413px;
    }

    .contact-form {
        max-width: 100%;
    }
}

@media screen and (min-width: 1100px) {

    .faq-content-container h2:nth-child(1),
    .contact-heading h2:nth-child(2) {
        font-size: 3rem;
    }
}