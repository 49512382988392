.terms-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-color: #050d15;
  overflow-x: hidden;
}

.bgEllipsis {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(/static/media/bgEllipsis.292f9f05.svg);
  background-repeat: no-repeat;
  background-position: left;
  z-index: 1;
}

.terms-wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #ffffff15;
    display: flex;
    flex-direction: column;
    z-index: 2;
    align-items: center;
    padding: 120px 10px;
    position: relative;
}

.terms-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    position: relative;
}

.termsBtn {
    top: 9px;
    left: 0;
    z-index: 1;
}

.terms-heading {
    align-self: center !important;
    width: 100%;
    text-align: center;
}

.terms-content {
    width: 100%;
}

.term {
    margin-bottom: 30px;
}

.term:last-child {
    margin-bottom: 0;
}

.term .title {
    font-weight: 600 !important;
    font-size: 1.1rem;
    margin: 5px 0;
}

.term .desc {
    font-weight: 400 !important;
    font-size: .9rem;
    margin: 5px 0;
    line-height: 22px;
}

@media screen and (min-width: 400px) {
    .terms-wrapper {
        padding: 120px 30px;
    }
}

@media screen and (min-width: 600px) {
    .terms-wrapper {
        width: calc(100% - 50px);
        align-self: flex-end;
        padding: 120px 30px;
    }
    .term .title {
      font-size: 1.2rem;
    }
    .term .desc {
      font-size: 1rem;
    }
}


@media screen and (min-width: 1024px) {
    .bgEllipsis{
        left: 100px;
    }
    .terms-wrapper {
        padding: 120px 50px 50px;
    }
}

@media screen and (min-width: 1300px) {
    .terms-wrapper {
        padding: 120px 100px 50px;
    }
}

@media screen and (min-width: 1400px) {
    .terms-wrapper {
        padding: 120px 100px 50px;
    }
}

@media screen and (min-width: 1700px) {
    .terms-wrapper {
        padding: 120px 100px 50px;
    }
}