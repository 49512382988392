.section {
    width: 100%;
    max-width: 1500px;
    align-self: center;
    scroll-margin-top: 100px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    margin: 50px 0 100px;
    padding: 0 10px;
}
.about-us{
    margin-bottom: 0px;
}
.about-top,
.section-content,
.intro,
.info-text-wrapper,
.feature-intro-wrapper,
.feature-outline,
.feature-wrapper,
.feature-info,
.feature-outline2,
.feature-intro-wrapper2,
.feature3-info-wrapper,
.feature4-wrapper,
.feature4-outline {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title-btn {
    background-color: #1e252c;
    border-radius: 30px;
    width: 170px;
    border: 0;
    color: #ffffff90;
    align-self: flex-end;
    margin: 0 0 50px;
    text-align: center;
    padding: 8px 0;
    font-size: .9rem;
    box-shadow: 0 0 10px #000;
}

.about-heading {
    font-weight: 900;
    font-size: 1.5rem;
    font-style: italic;
    margin-bottom: 10px;
}

.gradient-text {
    background-clip: text;
    background-image: linear-gradient(90deg, #72efdd, #26c5f3, #b429f9 30%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
    font-size: 1.7rem;
    font-weight: 400;
}

.gradient-text span {
    text-transform: uppercase;
    font-weight: 300;
}

.glowing-text {
    align-self: flex-end;
    font-size: 1rem;
    text-align: right;
    text-shadow: 0 0 10px #fff;
    font-weight: 400;
    font-style: italic;
}

.vertical-divider {
    width: 0.5px;
    height: 70px;
    align-self: center;
    background-color: #ffffff30;
    margin: 20px 0;
}

.info-text-wrapper {
    align-self: center;
    align-items: center;
    justify-content: center;
}

.info-text {
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: 300;
}

.info-text span {
    font-weight: 700;
    font-size: 1.2rem;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff50;
}

.info-gradient1 {
    background-image: linear-gradient(90deg, #5591f550, #b429f950);
}

.info-gradient2 {
    background-image: linear-gradient(90deg, #b429f950, #5591f550);
}

.info-gradient3 {
    background-image: linear-gradient(180deg, #5591f550, #b429f950);
}

.phone-icon {
    align-self: flex-end;
    margin-top: 50px;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    margin-right: -35px;
}

/* Features Styles */
.feature-heading {
    text-align: center;
    margin-bottom: 20px;
}

.feature-intro-wrapper {
    margin-top: -50px;
}

.feature-info-text {
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 5px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 275;
}

.feature-info-text:nth-child(2) {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 900;
    font-family: "Raleway", sans-serif !important;
}

.feature-outline {
    background-image: url('../../assets/featuresBgImg.svg');
    background-repeat: no-repeat;
    background-position: center -90px;
    background-size: contain;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
}

.feature-wrapper {
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    max-width: 360px;
}

.icon-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    align-self: center;
    background-image: linear-gradient(180deg, #1a2129, #08101850);
    margin-bottom: 20px;
}

.feature-icon {
    width: 25px;
    height: 25px;
}

.feature-info {
    align-items: center;
    justify-content: center;
    max-width: 340px;
}

.feature-info h3 {
    font-size: 1rem;
    font-weight: 900;
    width: 170px;
    text-align: center;
}

.feature-info p {
    font-size: .9rem;
    font-weight: 300;
    text-align: center;
    margin-top: 10px;
    height: 85px;
}

.feature2-section {
    width: 100%;
    display: flex;
}

.feature2content {
    max-width: auto;
}

.feature-intro-container2 {
    background-image: url('../../assets/feature2Img.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.feature-intro-wrapper2 {
    align-items: flex-end;
}

.feature-info-text2 {
    text-align: right;
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 300;
}

.feature-info-text2:nth-child(2) {
    font-size: 1.4rem;
    font-weight: 900;
}

.feature-outline2 {
    align-items: center;
    justify-content: space-between;
}

.feature-wrapper2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.check-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.feature-wrapper2 p,
.feature-wrapper2 svg {
    color: #72efdd;
}

.feature-wrapper2 svg {
    box-shadow: 0 10px 10px #72efdd;
}

.feature3-section {
    margin: 70px 0 70px;
}

.feature3-heading {
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: -30px;
}

.feature3-info-wrapper p {
    text-align: center;
    font-weight: 300;
}

.feature3-info-wrapper p:nth-child(1) {
    font-weight: 900;
    font-size: 1.5rem;
    margin: -30px 0 10px;
}

.feature3-info-wrapper p:nth-child(2) {
    max-width: 600px;
    align-self: center;
}

.small-info-text {
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 10px;
}

.small-info-text:last-child {
    font-weight: 600;
    width: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #b429f9 30%, #ffffff, #5591f5 70%);
}

.feature4-section {
    position: relative;
    margin: 0 0 150px;
}

.feature4-bg {
    position: absolute;
    left: -10px;
}

.feature4-text h2 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 28px;
    text-align: center;
}

.feature4-outline {
    justify-content: center;
    align-self: center;
    align-items: center;
}

.feature4 {
    display: flex;
    align-items: flex-end;
    margin: 20px 0;
    width: 240px;
}

.feature-icons {
    width: 90px;
    margin-right: 10px;
}

.feature4 p {
    font-size: .8rem;
    font-weight: 500;
    margin-bottom: 3px;
}


/* Media Queries */
@media screen and (min-width: 320px) {
    .section {
        padding: 0 20px;
    }

}

@media screen and (min-width: 360px) {
    .feature3-heading {
        margin-bottom: -40px;
    }
}

@media screen and (min-width: 600px) {
    .section {
        padding: 0 30px;
    }

    .gradient-text {
        font-size: 2.5rem;
    }

    .glowing-text {
        font-size: 1.1rem;
        max-width: 520px;
        align-self: flex-start;
        margin-left: 170px;
    }

    .info-text {
        font-size: 1.1rem;
    }

    .info-text span {
        font-size: 1.4rem;
    }

    .feature-info-text {
        font-size: 1.2rem;
    }

    .feature-info-text:nth-child(2) {
        font-size: 1.5rem;
    }

    .feature-intro-container2,
    .feature-outline2 {
        padding: 0 30px;
    }

    .feature-outline2 {
        flex-direction: row;
        padding: 20px;
    }

    .feature-info-text2 {
        font-size: 1.2rem;
    }

    .feature-info-text2:nth-child(2) {
        font-size: 1.6rem;
    }

    .feature-info-text2:nth-child(3) {
        max-width: 500px;
    }

    .feature3-heading {
        margin-bottom: -100px;
    }

    .adaptive-img {
        width: 500px;
        height: 400px;
        align-self: center;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -100px 0 10px;
        font-size: 2rem;
    }
}

@media screen and (min-width: 650px) {
    .feature-info-text2 {
        font-size: 1.4rem;
    }

    .feature-info-text2:nth-child(2) {
        font-size: 2rem;
    }
}

@media screen and (min-width: 768px) {
    .feature-info-text2 {
        font-size: 1.4rem;
    }

    .feature-info-text2:nth-child(2) {
        font-size: 2rem;
    }

    .feature-info-text2:nth-child(3) {
        max-width: 550px;
    }
    .feature4-section {
        justify-content: center;
    }
    .feature4-wrapper, .feature4-section {
        flex-direction: row;
        align-items: center;
    }
    .feature4-wrapper{
        max-width: 1000px;
        align-self: center;
        justify-content: space-between;
    }
    .feature4-text {
        max-width: 450px;
    }
    .feature4-text h2 {
        text-align: left;
    }
    .feature4-outline {
        width: auto;
    }
    .feature4{
        margin: 10px 0;
    }
}

@media screen and (min-width: 900px) {
    .gradient-text {
        font-size: 3rem;
    }

    .glowing-text {
        font-size: 1.2rem;
    }

    .info-text {
        font-size: 1.2rem;
    }

    .info-text span {
        font-size: 1.6rem;
    }

    .feature3-section {
        margin: 120px 0 70px;
    }

    .feature3-heading {
        margin-bottom: -200px;
    }

    .adaptive-img {
        width: 800px;
        height: 700px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -200px 0 10px;
    }
}

@media screen and (min-width: 1024px) {
    .section {
        padding: 0 40px;
        scroll-margin-top: 140px;
    }

    .section-content {
        max-width: 838px;
        align-self: center;
    }

    .info-text,
    .info-text span {
        letter-spacing: 1px;
    }

    .feature-content {
        max-width: 1100px;
    }

    .features-img {
        width: 70%;
        align-self: center;
    }

    .feature-heading {
        font-size: 1.1rem;
    }

    .feature-intro-wrapper {
        margin-top: -50px;
    }

    .feature-info-text {
        font-size: 1.875rem;
    }

    .feature-info-text:nth-child(2) {
        font-size: 3.75rem;
    }

    .feature-outline {
        flex-direction: row;
        justify-content: space-between;
    }

    .feature2-content {
        max-width: 100%;
    }

    .feature-intro-wrapper2 {
        max-width: 1400px;
    }

    .feature-intro-container2,
    .feature-outline2 {
        padding: 0 40px;
    }

    .feature-info-text2:nth-child(2) {
        font-size: 2.5rem;
    }

    .feature-outline2 {
        margin-top: 40px;
    }

    .feature3-section {
        margin: 200px 0 70px;
    }

    .feature3-heading {
        margin-bottom: -230px;
        font-size: 1.3rem;
    }

    .adaptive-img {
        width: 1000px;
        height: 800px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -230px 0 10px;
        font-size: 2.5rem;
    }

    .feature3-info-wrapper p:nth-child(2) {
        font-size: 1.2rem;
    }
    .feature4-text h2 {
        font-size: 1.5rem;
        line-height: 40px;
        width: 560px;
    }
    .feature4{
        width: 300px;
    }
    .feature-icons {
        width: 110px;
    }
    .feature4 p{
        font-size: .9rem;
    }
}

@media screen and (min-width: 1100px) {
    .feature-intro-container2 {
        height: 500px;
    }

    .feature-outline2 {
        max-width: 1150px;
        align-self: center;
        margin-top: 60px;
    }

    .feature3-heading {
        margin-bottom: -170px;
        font-size: 1.3rem;
    }

    .adaptive-img {
        width: 100%;
        height: auto;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -180px 0 10px;
    }
}

@media screen and (min-width: 1200px) {
    .feature3-heading {
        margin-bottom: -200px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -200px 0 10px;
    }
   
    
}

@media screen and (min-width: 1300px) {
    .feature3-heading {
        margin-bottom: -220px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -220px 0 10px;
    }

}

@media screen and (min-width: 1400px) {
    .feature3-heading {
        margin-bottom: -230px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -240px 0 10px;
    }
    .phone-icon{
        margin-right: -60px;
    }
}

@media screen and (min-width: 1500px) {
    .feature3-heading {
        margin-bottom: -250px;
    }

    .feature3-info-wrapper p:nth-child(1) {
        margin: -260px 0 10px;
    }
    .feature4-bg{
        left: -100px;
    }
}
@media screen and (min-width: 1670px) {
    .feature4-bg{
        left: -25%;
    }
}