/*.skip-substract-icon {
  width: 100%;
  height: 1844px;
  position: absolute;
  margin: 0 !important;
  right: 0;
  /*bottom: 478.2px;*/
  /*bottom: 241.2px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 3;
}*/
.grid-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr); 
  width: 100%;
}

.grid-header {
  display: contents;
}

.grid-header-cell {
  padding: 10px;
  color:#64748B;
font-size: 14px;
font-weight: 600;
line-height: 18px;
text-align: center;

}

.grid-row {
  display: contents;
  position: relative;
}
.grid-row.first-row .grid-time-cell,
.grid-row.first-row .grid-cell {
    margin-top: 10px; /* Set margin-top for the first row's columns */
}
.horizontal-line.first-row-line {
  margin-top: 10px; /* Set margin-top for the first row's horizontal line */
}
.grid-time-cell {
  padding: 10px;
  color:#64748B;
font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;  
font-family: Poppins, sans-serif !important;
}

.grid-cell.last-cell {
  border-right: none; /* Remove right border for the last cell */
}
.grid-cell {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 45px;
  width:80px
}
.time-label {
  position: relative;
  z-index: 1; 
}

.horizontal-line {
  grid-column: 1 / -1; 
  height: 1px; 
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: 84;
  margin-top: -46px;
  width: 87.5%;
}
.horizontal-line2{
  grid-column: 1 / -1; 
  height: 1px; 
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: 95;
  margin-top: 0px;
  width: 85%;
}
.calenderweekdata{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.task-element-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.task-element-priority {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.task-element-deadline {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  height: 10px;
  opacity: 0.5px;
  color: #FFFFFF;
}
.task-element-title {
  height: 10px;
  opacity: 0.8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--font-raleway);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;
}
.task-element-icon {
  background: #FFFFFF33;
  width: 22px;
  height: 22px;
  border-radius: 8px;
  opacity: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task-elements-group-right {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
}
.task-elements-group-left {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}
.task-element {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 15px;
}
.task-card {
  width: 100%;
  height: 36px;
  gap: 0px;
  /* opacity: 0px; */
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.task-card-container {
  width: 100%;
  height: 36px;
  border-radius: 20px;
  /* opacity: 0px; */
  background: #1E293B80;
  box-shadow: 0px 4px 8px 0px #00000040;
  display: flex;
  flex-direction: row;
}
.tasks-list-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;
  justify-content: center;*/
  /*gap: 10px;*/
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
}
.tasks-list-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  height: 9px;
  opacity: 0.6px;
  font-family: var(--font-raleway);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #FFFFFF;

}
.tasks-list-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--font-raleway);
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #FFFFFF;
}
.tasks-list-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  /*justify-content: center;*/
  gap: 50px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 15px;
}
.month-tasks-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*gap: 20px;*/
  /*top: -285px;*/
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  position: relative;
}
.week-tasks-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*gap: 20px;*/
  /*top: -285px;*/
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  position: relative;
}
.today-tasks-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*gap: 20px;*/
  /*top: -285px;*/
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  position: relative;
}
.tasks-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*gap: 20px;*/
  /*top: -285px;*/
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  position: relative;
}
.meeting-color {
  border-radius: var(--br-xl);
  background-color: rgba(52, 211, 153, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.lecture-color {
  border-radius: var(--br-xl);
  background-color: rgba(38, 197, 243, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.task-color {
  border-radius: var(--br-xl);
  background-color: rgba(106, 0, 244, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.event-task-time img {
  display: flex;
}
.event-task-time {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.event-icons {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.event-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.event-title {
  font-family: var(--font-raleway);
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.event-location {
  font-family: var(--font-raleway);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.event-timeframe {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1px;
}
.event-timeframe span {
  font-size: 10px;
  font-weight: 400;
  font-family: Poppins, sans-serif!important;
  text-align: center;
  line-height: 12px;
}
.event-timeframe .gap{
  line-height: 5px;
}
.v-line {
  width: 3px;
  height: 57px;
  gap: 0px;
  opacity: 0px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  left: 1px;
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)), #1e293b;
}
.event-card {
  /*background-color: rgba(52, 211, 153, 0.4);*/
  /*box-shadow: 0px 8px 32px 0px #0000000F;*/
  /*box-shadow: 0px 4px 8px 0px #00000008;*/
  width: 100%;
  height: 57px;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.no-events {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.upcomingmeetings-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.upcomingmeetings-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.tomorrow-group-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}
.skip-substract-icon {
  width: 100%;
  /* height: 2000px; */
  top: 540px;
  gap: 0px;
  position: absolute;
  margin: 0 !important;
  /* opacity: 0px; */
  /* border-radius: 60px 60px 0px 0px; */
  /* background: rgba(30, 41, 59, 1); */
}
.footer-wrapper {
  width: 100%;
  height: 300px; 
  background: rgb(24,41,57);
  position: absolute;
  top: 2100px;
  
}

.group-logo-icon {
  height: 37.8px;
  width: 145.9px;
  position: relative;
}
.group-left,
.group-left-wrapper {
  display: flex;
  justify-content: flex-start;
}
.group-left {
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.group-left-wrapper {
  height: 67px;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
}
.textlabel-text,
.textlabel-text1 {
  position: relative;
  display: inline-block;
}
.textlabel-text {
  letter-spacing: 0.05em;
  font-weight: 300;
  min-width: 45px;
}
.textlabel-text1 {
  letter-spacing: 0.64px;
  line-height: 16px;
  min-width: 65px;
  white-space: nowrap;
}
.textlabel-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs-5) 0 0;
  color: var(--basic-white);
}
.textlabel-text2 {
  position: relative;
  letter-spacing: 0.05em;
  line-height: 16px;
  font-weight: 300;
  display: inline-block;
  min-width: 89px;
  white-space: nowrap;
}
.textlabel-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs-5) 0 0;
}
.textlabel-text3 {
  position: relative;
  letter-spacing: 0.05em;
  line-height: 16px;
  font-weight: 300;
  display: inline-block;
  min-width: 90px;
  white-space: nowrap;
}
.textlabel-text-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs-5) 0 0;
}
.skip-vector-icon {
  height: 24px;
  width: 0;
  position: relative;
  border-radius: var(--br-xl);
  object-fit: contain;
  display: none;
}
.group-center {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 19.5px 0;
  gap: var(--gap-xl);
}
.group-center-wrapper {
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.view-stroke {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  width: 100%;
  height: 100%;
}
.textlabel-text4 {
  position: absolute;
  top: 18px;
  left: 40px;
  letter-spacing: 0.05em;
  font-weight: 300;
  display: none;
  align-items: center;
  width: 90px;
  height: 10px;
  opacity: 0.8;
}
.skip-expand-more-icon {
  position: absolute;
  top: 13px;
  left: 13px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
.group-user {
  height: 46px;
  width: 46px;
  position: relative;
}
.view-stroke1 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
}
.textlabel-text5 {
  flex: 1;
  position: relative;
  line-height: 22px;
}
.avatar-mec {
  width: 32px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0;
  box-sizing: border-box;
  z-index: 1;
}
.textlabel-text6,
.textlabel-text7 {
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 300;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-text6 {
  height: 10px;
  min-width: 90px;
  white-space: nowrap;
}
.textlabel-text7 {
  align-self: stretch;
  height: 8px;
  font-size: 10px;
}
.textlabel-text-parent {
  align-self: stretch;
  flex-direction: column;
  gap: 6px;
}
.avatar-mec-parent,
.frame-wrapper,
.textlabel-text-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-9xs) 0 0;
  text-align: left;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
}
.avatar-mec-parent {
  width: 130px;
  flex-direction: row;
  gap: var(--gap-5xs);
}
.skip-expand-more-icon1 {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
}
.skip-expand-more-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xs) 0 0;
}
.group-right,
.group-user1,
.navbar-mec {
  display: flex;
  flex-direction: row;
  /*align-items: flex-start;*/
  align-items: center;
  box-sizing: border-box;
}
.group-user1 {
  width: 220px;
  justify-content: space-between;
  padding: var(--padding-6xs) var(--padding-5xs);
  position: relative;
  gap: var(--gap-xl);
  text-align: center;
  font-family: var(--font-raleway);
}
.group-right,
.navbar-mec {
  max-width: 100%;
}
.group-right {
  width: 719px;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: var(--padding-sm) 0;
  gap: 10px;
  color: var(--basic-white);
}
.navbar-mec {
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(70px);
  background-color: rgba(255, 255, 255, 0.04);
  overflow: hidden;
  /*justify-content: flex-start;*/
  justify-content: center;
  padding: var(--padding-5xs) 50px;
  gap: var(--gap-31xl);
  width: 100%;
  height: 100%;
}
.view-ellipse {
  position: absolute;
  top: 63px;
  left: 355px;
  /*border-radius: 50%;
  background-color: var(--basic-white);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  width: 5px;
  height: 5px;
  transform: rotate(180deg);
  transform-origin: 0 0;
  z-index: 1;
}
.navbar-mec-parent {
  align-self: stretch;
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 99;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
  font-family: var(--font-poppins);
}
.textlabel-title {
  position: relative;
  line-height: 70px;
  font-weight: 500;
  height: 17px;
  display: flex; /*inline-block;*/
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  /*min-width: 100px;*/
}
.view-vector-icon {
  height: 2px;
  width: 1154px;
  position: relative;
  margin: 0 !important;
  /*right: -1054px;*/
  bottom: 16px;
  border-radius: var(--br-330xl);
  z-index: 1;
  flex-shrink: 0;
}
.frame-wrapper2,
.textlabel-title-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-title-parent {
  flex-direction: row;
  position: relative;
}
.frame-wrapper2 {
  flex-direction: column;
  padding: var(--padding-12xs) 0 0;
}
.skip-arrow-right-icon {
  height: 20px;
  width: 20px;
  position: relative;
}
.textlabel-title1 {
  align-self: stretch;
  height: 17px;
  position: relative;
  line-height: 70px;
  font-weight: 900;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title-wrapper {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-12xs) 0 0;
  box-sizing: border-box;
  min-width: 226px;
}
.frame-parent2,
.frame-wrapper1,
.textlabel-title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent2 {
  align-self: stretch;
  flex-direction: row;
  gap: 9.5px;
}
.frame-wrapper1 {
  width: 487px;
  flex-direction: column;
  padding: var(--padding-7xs) 0 0;
  box-sizing: border-box;
}
.skip-bmec {
  width: 171px;
  font-family: var(--font-raleway);
  font-weight: 800;
  font-size: var(--font-size-sm);
  color: var(--basic-white);
}
.frame-parent1 {
  width: 1154px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  max-width: 100%;
  gap: var(--gap-xl);
  font-size: var(--font-size-5xl);
}
.group-header {
  align-self: stretch;
}
.textlabel- {
  flex: 1;
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 30px;
}
.group-weekday {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-1 {
  flex: 1;
  position: relative;
  line-height: 14px;
}
.group-weekday1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-2 {
  flex: 1;
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 30px;
}
.group-weekday2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-3 {
  flex: 1;
  position: relative;
  line-height: 14px;
}
.group-weekday3 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-4 {
  flex: 1;
  position: relative;
  line-height: 14px;
}
.group-weekday4 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-5 {
  flex: 1;
  position: relative;
  line-height: 14px;
}
.group-weekday5 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
}
.textlabel-6 {
  flex: 1;
  position: relative;
  line-height: 14px;
}
.group-weekday-parent,
.group-weekday6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-weekday6 {
  flex: 1;
  padding: 0 0 var(--padding-8xs);
}
.group-weekday-parent {
  align-self: stretch;
  padding: 0 0 150px;
  gap: var(--gap-4xs);
}
.day,
.view-136 {
  position: absolute;
}
.view-136 {
  top: 7px;
  left: 9px;
  border-radius: 6px;
  background-color: var(--primary-purple-50);
  border: 1px solid rgba(0, 133, 255, 0.2);
  box-sizing: border-box;
  width: 30px;
  height: 35px;
  display: none;
}
.day {
  top: calc(50% - 8px);
  left: calc(50% - 5px);
  line-height: 14px;
}
.bg,
.just-day {
  position: relative;
  display: none;
}
.just-day {
  width: 40px;
  height: 40px;
  z-index: 2;
  text-align: left;
  color: var(--neutrals-cool-gray-600);
  font-family: var(--text-sm-font-normal);
}
.bg {
  height: 30px;
  width: 30px;
  background-color: var(--basic-white);
}
.textlabel-day {
  position: relative;
  line-height: 14px;
  font-weight: 200;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-9xs) var(--padding-6xs);
  opacity: 0.5;
}
.bg1 {
  height: 30px;
  width: 30px;
  position: relative;
  background-color: var(--basic-white);
  display: none;
}
.textlabel-day1 {
  position: relative;
  line-height: 14px;
  font-weight: 200;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-9xs) var(--padding-6xs);
  opacity: 0.5;
}
.textlabel-day2 {
  position: relative;
  line-height: 14px;
  font-weight: 200;
  display: inline-block;
  min-width: 16px;
}
.group-calendarelementsday2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-6xs) var(--padding-6xs);
  opacity: 0.5;
}
.textlabel-day3 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 6px;
}
.group-calendarelementsday3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-xs) var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day4 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 11px;
}
.group-calendarelementsday4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-6xs)
    var(--padding-3xs);
  opacity: 0.8;
}
.textlabel-day5 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 11px;
}
.group-calendarelementsday5 {
  width: 30px;
  border-radius: var(--br-5xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-6xs);
  box-sizing: border-box;
  opacity: 0.8;
}
.textlabel-day6 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 12px;
}
.group-calendarelementsday6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day7 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 12px;
}
.group-calendarelementsday7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-5xs) var(--padding-7xs)
    var(--padding-3xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day8 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 12px;
}
.group-calendarelementsday8 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day9 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 10px;
}
.group-calendarelementsday9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-7xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day10 {
  position: relative;
  line-height: 14px;
  font-weight: 600;
  display: inline-block;
  min-width: 12px;
}
.group-today {
  width: 30px;
  border-radius: var(--br-5xl);
  background-color: var(--neutrals-cool-gray-500);
  border: 1px solid var(--neutrals-cool-gray-500);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-9xs);
  z-index: 1;
}
.textlabel-day11 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 12px;
}
.indicator-icon,
.indicator-icon1 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday10 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day12 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.indicator-icon2,
.indicator-icon3 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday11 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day13 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 11px;
}
.indicator-icon4,
.indicator-icon5,
.indicator-icon6 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs)
    var(--padding-3xs);
  opacity: 0.8;
  z-index: 1;
}
.textlabel-day14 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 16px;
}
.indicator-icon7,
.indicator-icon8 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-6xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day15 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 16px;
}
.indicator-icon9 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday14 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-6xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day16 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.indicator-icon10,
.indicator-icon11,
.indicator-icon12 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday15 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day17 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.indicator-icon13,
.indicator-icon14 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday16 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day18 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.indicator-icon15 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday17 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day19 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 15px;
}
.indicator-icon16,
.indicator-icon17 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-6xs) var(--padding-7xs)
    var(--padding-5xs);
  opacity: 0.8;
}
.textlabel-day20 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.group-calendarelementsday19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day21 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 17px;
}
.group-calendarelementsday20 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-7xs) var(--padding-7xs)
    var(--padding-6xs);
  opacity: 0.8;
}
.textlabel-day22 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.indicator-icon18,
.indicator-icon19,
.indicator-icon20 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday21 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-textlabel-day {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 16px;
}
.group-calendarelementsday22 {
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-6xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day23 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 21px;
}
.indicator-icon21,
.indicator-icon22 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday23 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs)
    var(--padding-8xs);
  opacity: 0.8;
}
.textlabel-day24 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 21px;
}
.group-calendarelementsday24 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs)
    var(--padding-8xs);
  opacity: 0.8;
}
.textlabel-day25 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday25 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day26 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.indicator-icon23 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday26 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day27 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday27 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day28 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 20px;
}
.indicator-icon24,
.indicator-icon25 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday28 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-8xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day29 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.group-calendarelementsday29 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day30 {
  position: relative;
  line-height: 14px;
  font-weight: 300;
  display: inline-block;
  min-width: 22px;
}
.indicator-icon26 {
  height: 3px;
  width: 3px;
  position: relative;
  display: none;
}
.group-calendarelementsday30 {
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-7xs);
  opacity: 0.8;
}
.textlabel-day31 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 9px;
}
.group-calendarelementsday31 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-7xs)
    var(--padding-2xs);
  opacity: 0.5;
  font-family: var(--text-sm-font-normal);
}
.bg2 {
  height: 30px;
  width: 30px;
  position: relative;
  background-color: var(--basic-white);
  display: none;
}
.textlabel-day32 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 11px;
}
.group-calendarelementsdayem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs)
    var(--padding-3xs);
  opacity: 0.5;
  font-family: var(--text-sm-font-normal);
}
.bg3 {
  height: 30px;
  width: 30px;
  position: relative;
  background-color: var(--basic-white);
  display: none;
}
.textlabel-day33 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 12px;
}
.group-calendarelementsdayem1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-7xs);
  opacity: 0.5;
  font-family: var(--text-sm-font-normal);
}
.group-calendar-days-inner,
.group-calendarelementsday-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-calendarelementsday-parent {
  width: 264px;
  flex-wrap: wrap;
  gap: 5px 8.3px;
}
.group-calendar-days-inner {
  height: 170px;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
  margin-top: -135px;
  font-family: var(--font-poppins);
}
.view-144 {
  align-self: stretch;
  height: 30px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: rgba(203, 213, 225, 0.1);
  margin-top: -135px;
}
.group-calendar-days {
  align-self: stretch;
  height: 208px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
  box-sizing: border-box;
}
.calendar-simple,
.view-rectangle {
  border-radius: var(--br-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.calendar-simple {
  flex: 1;
  box-shadow: var(--mec-boxes);
  background-color: var(--color-gray-100);
  border: 1px solid var(--neutrals-cool-gray-800);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xl) var(--padding-23xl);
  gap: var(--gap-mini);
  min-width: 263px;
  z-index: 4;
  text-align: center;
  font-size: var(--text-sm-font-normal-size);
}
.view-rectangle {
  width: 351px;
  height: 305px;
  position: relative;
  backdrop-filter: blur(40px);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  display: flex
}
.textlabel-title2 {
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.all-clear {
  margin: 0;
}
.schedules-open-time {
  margin: 0;
  font-weight: 300;
}
.textlabel-text-txt {
  width: 100%;
}
.textlabel-text8 {
  position: absolute;
  top: 79px;
  left: 0;
  letter-spacing: 0.05em;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 267px;
  height: 40px;
  z-index: 2;
}
.skip-union-icon,
.wrapper-skip-union {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.skip-union-icon {
  overflow: hidden;
  object-fit: contain;
  transform: scale(2.014);
}
.wrapper-skip-union {
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.skip-note-stack-icon {
  position: absolute;
  top: 14px;
  left: 36px;
  width: 50px;
  height: 50px;
  z-index: 2;
}
.wrapper-skip-union-parent {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.frame-wrapper4 {
  position: absolute;
  height: calc(100% - 0.7px);
  top: 0;
  bottom: 0.7px;
  left: 72px;
  width: 123.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-text-group {
  align-self: stretch;
  height: 119px;
  position: relative;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
}
.textlabel-title-group {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
}
.textlabel-7 {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.05em;
  line-height: 24px;
  font-weight: 800;
  font-family: var(--font-raleway);
  color: var(--basic-white);
  text-align: left;
  display: inline-block;
  min-width: 98px;
}
.skip-bmec1 {
  cursor: pointer;
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  padding: var(--padding-11xs) var(--padding-xl);
  /*background-color: var(--mec-primary-mec-dark-gradient);*/
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
}
.skip-bmec1:hover {
  /* background-color: rgba(135, 38, 255, 0.09); */
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
}
.group-upcomingmeetings,
.view-rectangle1 {
  backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  max-width: 100%;
}
.group-upcomingmeetings {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-17xl) var(--padding-22xl) var(--padding-9xl);
  gap: 33px;
  min-width: 263px;
}
.view-rectangle1 {
  width: 351px;
  height: 305px;
  position: relative;
  display: none;
}
.textlabel-title3 {
  width: 129px;
  position: relative;
  line-height: 15px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.view-stroke2 {
  height: 29px;
  width: 102px;
  position: absolute;
  margin: 0 !important;
  top: -8px;
  left: -15px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  z-index: 1;
}
.textlabel-text9 {
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 300;
  display: inline-block;
  min-width: 73px;
  z-index: 2;
  line-height: 12px;
}
.frame-wrapper5,
.view-stroke-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.view-stroke-parent {
  flex-direction: row;
  position: relative;
}
.frame-wrapper5 {
  flex-direction: column;
  padding: var(--padding-10xs) 0 0;
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
}
.textlabel-title-container {
  width: 253px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.textlabel-text10 {
  flex: 1;
  position: relative;
  z-index: 1;
  width: 18px;
  height: 39px;
  top: 11px;
  left: -25px;
  font-family: var(--font-poppins);
  font-size: 50px;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
}
.textlabel-text-wrapper1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 80px;
  /* padding: 0 71px var(--padding-2xs) 72px; */
  text-align: center;
  font-size: 50px;
  font-family: var(--font-poppins);
}
.textlabel-text11 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  color: var(--color-gray-500);
  text-align: center;
  z-index: 1;
}
.view-rectangle2 {
  height: 74px;
  width: 267px;
  position: relative;
  box-shadow: var(--mec-boxes);
  border-radius: var(--br-xl);
  background-color: var(--color-gray-100);
  border: 1px solid var(--neutrals-cool-gray-800);
  box-sizing: border-box;
  display: none;
}
.skip-meeting-icon {
  height: 18px;
  width: 18px;
  position: relative;
  z-index: 2;
}
.textlabel-text12 {
  flex: 1;
  position: relative;
  line-height: 20px;
  z-index: 2;
}
.skip-meeting-parent {
  width: 57px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.textlabel-text13 {
  height: 10px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-family: var(--font-raleway);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 60px;
  z-index: 2;
}
.frame-parent4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.skip-lecture-icon {
  height: 18px;
  width: 18px;
  position: relative;
  z-index: 2;
}
.textlabel-text14 {
  flex: 1;
  position: relative;
  line-height: 20px;
  z-index: 2;
}
.skip-lecture-parent {
  width: 54px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.textlabel-text15 {
  height: 10px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-family: var(--font-raleway);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 57px;
  z-index: 2;
}
.frame-parent5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.skip-task {
  margin: 0;
  height: 18px;
  width: 18px;
  position: relative;
  z-index: 2;
}
.textlabel-text16 {
  flex: 1;
  position: relative;
  line-height: 20px;
  z-index: 2;
}
.skip-task-parent {
  width: 46px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.textlabel-text17 {
  align-self: stretch;
  height: 10px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-family: var(--font-raleway);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 2;
}
.frame-parent6 {
  width: 49px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.view-rectangle-parent {
  align-self: stretch;
  box-shadow: var(--mec-boxes);
  border-radius: var(--br-xl);
  background-color: var(--color-gray-100);
  border: 1px solid var(--neutrals-cool-gray-800);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px var(--padding-5xl) var(--padding-mid) var(--padding-4xl);
  gap: var(--gap-xl);
  z-index: 1;
  font-size: var(--font-size-base);
  font-family: var(--font-poppins);
}
.calendar-simple-parent,
.frame-wrapper3,
.group-activity {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.group-activity {
  flex: 1;
  backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-17xl) var(--padding-22xl) var(--padding-9xl);
  gap: var(--gap-7xl);
  min-width: 263px;
}
.calendar-simple-parent,
.frame-wrapper3 {
  flex-direction: row;
}
.calendar-simple-parent {
  width: 1153px;
  flex-wrap: wrap;
  gap: var(--gap-31xl);
}
.frame-wrapper3 {
  align-self: stretch;
}
.textlabel-8 {
  position: relative;
  font-size: 25px;
  letter-spacing: 0.05em;
  line-height: 24px;
  display: inline-block;
  font-family: var(--font-poppins);
  color: var(--basic-white);
  text-align: center;
  min-width: 15px;
}
.frame-parent3,
.skip-bmec2 {
  display: flex;
  justify-content: flex-start;
}
.skip-bmec2 {
  cursor: pointer;
  border: 2px;
  padding: var(--padding-3xs) var(--padding-sm) var(--padding-3xs) var(--padding-mid);
  border-radius: var(--br-xl);
  background: linear-gradient(90deg, #6200ea 0%, #26c5f3 100%);
  background-repeat: no-repeat;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  z-index: 4;
  position: absolute;
  top: 565px;
  right: 34px;
}
.skip-bmec2:hover,
.skip-bmec4:hover {
  border: 2px;
  box-sizing: border-box;
}
.frame-parent3 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-3xl);
  max-width: 100%;
}
.view-rectangle3 {
  width: 351px;
  height: 398px;
  position: relative;
  backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.textlabel-title4 {
  position: relative;
  display: inline-block;
  min-width: 98px;
  z-index: 1;
  line-height: 15px;
}
.textlabel-title-frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
  box-sizing: border-box;
  min-width: 64px;
}
.view-stroke3 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-200);
  display: none;
}
.textlabel-text18 {
  flex: 1;
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 500;
  z-index: 2;
  height: 20px;
  width: 24px;
}
.frame-wrapper6,
.view-stroke-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.view-stroke-group {
  width: 24px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-9xs) 0 0;
  box-sizing: border-box;
  z-index: 1;
}
.frame-wrapper6 {
  flex: 1;
  flex-direction: column;
  min-width: 58px;
  text-align: center;
  font-size: 13px;
  color: var(--color-gray-500);
  font-family: var(--font-poppins);
}
.group-switcher-icon {
  height: 24px;
  width: 48px;
  position: relative;
  z-index: 1;
}
.frame-parent8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.nothing-on-for-tomorrow-yet {
  margin: 0;
}
.schedule-lectures-meetings {
  margin: 0;
  font-weight: 300;
}
.textlabel-text-txt1 {
  width: 100%;
}
.textlabel-text19 {
  position: absolute;
  top: 73px;
  left: 0;
  letter-spacing: 0.05em;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 235px;
  height: 60px;
  z-index: 2;
}
.skip-union-icon1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}
.skip-calendar-month-icon {
  position: absolute;
  top: 8px;
  left: 37.4px;
  width: 50px;
  height: 50px;
  z-index: 2;
}
.skip-union-parent {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.frame-wrapper8 {
  position: absolute;
  top: 0;
  left: 55.6px;
  width: 123.4px;
  height: 118.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-text-parent1 {
  height: 133px;
  flex: 1;
  position: relative;
}
.frame-wrapper7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mini);
}
.image-icon {
  width: 20px;
  height: 20px;
  position: relative;
}
.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.textlabel-9 {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.05em;
  line-height: 24px;
  font-weight: 800;
  font-family: var(--font-raleway);
  color: var(--basic-white);
  text-align: left;
  display: inline-block;
  min-width: 94px;
}
.image-icon1 {
  width: 20px;
  height: 20px;
  position: relative;
}
.frame-parent9,
.image-container,
.skip-bmec3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-container {
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
}
.frame-parent9,
.skip-bmec3 {
  align-self: stretch;
}
.skip-bmec3 {
  cursor: pointer;
  /* padding: var(--padding-11xs) var(--padding-36xl) var(--padding-11xs) 61px; */
  /*background-color: var(--mec-primary-mec-dark-gradient);*/

  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  padding: var(--padding-11xs) var(--padding-xl);

  /*border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1E293B, #1E293B) padding-box,
    linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)) border-box;*/
  overflow-x: auto;
  flex-direction: row;
  gap: 5.5px;
  z-index: 1;
}
.frame-parent9 {
  flex-direction: column;
  gap: 57px;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
  top: 30px;
  position: relative;
}
.group-tomorrow,
.view-rectangle4 {
  backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  max-width: 100%;
}
.group-tomorrow {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 33px var(--padding-22xl) var(--padding-18xl) var(--padding-23xl);
  gap: 80px;/*var(--gap-9xl);*/
  z-index: 4;
}
.view-rectangle4 {
  width: 351px;
  height: 294px;
  position: relative;
  display: none;
}
.textlabel-title5 {
  align-self: stretch;
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-title-wrapper1 {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
}
.view-stroke4 {
  height: 29px;
  width: 60px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  display: none;
}
.textlabel-text20 {
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 300;
  display: inline-block;
  min-width: 31px;
  z-index: 1;
}
.frame-parent10,
.group-add {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.group-add {
  height: 29px;
  width: 60px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  justify-content: flex-start;
  padding: var(--padding-8xs) var(--padding-mini);
  box-sizing: border-box;
  z-index: 1;
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
}
.frame-parent10 {
  align-self: stretch;
  justify-content: space-between;
  padding: 0 0 var(--padding-5xs);
  gap: var(--gap-xl);
  font-size: var(--font-size-xl);
}
.view-rectangle5 {
  border-radius: var(--br-xl);
  background-color: rgba(52, 211, 153, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.skip-mask-icon,
.view-rectangle-group,
.view-rectangle5 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.skip-mask-icon {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
}
.view-rectangle-group {
  margin: 0 !important;
}
.checkbox-mec-icon {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.checkbox-mec-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
}
.textlabel-text21 {
  width: 140px;
  height: 30px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 2;
}
.textlabel-text-wrapper2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  min-width: 108px;
}
.skip-more-vert-icon {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 2;
}
.group-category,
.skip-more-vert-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}
.group-category {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini) var(--padding-6xs);
  position: relative;
  gap: var(--gap-mini);
  z-index: 1;
}
.view-rectangle6 {
  border-radius: var(--br-xl);
  background-color: rgba(38, 197, 243, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.skip-mask-icon1,
.view-rectangle-container,
.view-rectangle6 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.skip-mask-icon1 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
}
.view-rectangle-container {
  margin: 0 !important;
}
.checkbox-mec-icon1 {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.checkbox-mec-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
}
.textlabel-text22 {
  width: 140px;
  height: 30px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 2;
}
.textlabel-text-wrapper3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  min-width: 108px;
}
.skip-more-vert-icon1 {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 2;
}
.skip-more-vert-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}
.group-category-parent,
.group-category1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.group-category1 {
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini) var(--padding-6xs);
  position: relative;
  z-index: 1;
}
.group-category-parent {
  flex-direction: column;
}
.view-rectangle7 {
  border-radius: var(--br-xl);
  background-color: rgba(106, 0, 244, 0.4);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
}
.skip-mask-icon2,
.view-rectangle-parent1,
.view-rectangle7 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.skip-mask-icon2 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
}
.view-rectangle-parent1 {
  margin: 0 !important;
}
.checkbox-mec {
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.checkbox-mec-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}
.textlabel-text23 {
  width: 140px;
  height: 30px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 2;
}
.textlabel-text-wrapper4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  min-width: 108px;
}
.skip-more-vert-icon2 {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 2;
}
.skip-more-vert-frame {
  flex-direction: column;
  padding: var(--padding-8xs) 0 0;
}
.group-categories,
.group-category2,
.group-tomorrow-parent,
.skip-more-vert-frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-category2 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini) var(--padding-6xs);
  position: relative;
  gap: var(--gap-mini);
  z-index: 1;
}
.group-categories,
.group-tomorrow-parent {
  flex-direction: column;
  max-width: 100%;
}
.group-categories {
  align-self: stretch;
  backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  padding: var(--padding-11xl) var(--padding-22xl) var(--padding-18xl)
    var(--padding-23xl);
  gap: var(--gap-mini);
  z-index: 4;
  font-size: var(--font-size-mini);
}
.group-tomorrow-parent {
  width: 351px;
  gap: var(--gap-28xl);
  min-width: 351px;
}
.textlabel-title6 {
  align-self: stretch;
  position: relative;
  line-height: 28px;
}
.skip-world-icon {
  height: 14px;
  width: 14px;
  position: relative;
}
.textlabel-text24 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.05em;
}
.textlabel-text-wrapper5 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
}
.frame-wrapper9,
.skip-world-parent,
.textlabel-text-wrapper5,
.textlabel-title-parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.skip-world-parent {
  width: 124px;
  flex-direction: row;
  gap: 5px;
  font-size: var(--font-size-xs);
  color: rgba(255, 255, 255, 0.5);
}
.frame-wrapper9,
.textlabel-title-parent1 {
  flex-direction: column;
}
.textlabel-title-parent1 {
  align-self: stretch;
  gap: 2px;
}
.frame-wrapper9 {
  flex: 1;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.group-switcher-icon1 {
  width: 48px;
  height: 24px;
  position: relative;
}
.group-switcher-wrapper {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.textlabel-label- {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 24px;
  cursor: pointer;
}
.skip-day {
  /* height: 23px; */
  /* width: 44px; */
  border-radius: var(--br-31xl);
  background-color: var(--neutrals-cool-gray-500);
  border: 1px solid var(--neutrals-cool-gray-500);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs);
}
.textlabel-label-1 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 34px;
  cursor: pointer;
}
.button-week {
  height: 23px;
  border-radius: var(--br-31xl);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
  opacity: 0.8;
}
.textlabel-label-2 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 39px;
  cursor: pointer;
}
.button-month {
  height: 23px;
  border-radius: var(--br-31xl);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
  opacity: 0.8;
}
.textlabel-label-3 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 28px;
  cursor: pointer;
}
.button-year {
  height: 23px;
  border-radius: var(--br-31xl);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
  opacity: 0.8;
}
.tabbar-segmented-group-mec {
  border-radius: var(--br-31xl);
  background-color: var(--color-gray-400);
  overflow-x: auto;
  padding: var(--padding-10xs);
}
.frame-parent11,
.frame-parent12,
.tabbar-segmented-group-mec {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent12 {
  gap: 31px;
  margin-left: -142px;
  font-size: var(--font-size-xs);
  font-family: var(--font-poppins);
}
.frame-parent11 {
  align-self: stretch;
  max-width: 100%;
}
.textlabel-10 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line- {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-current-time-icon {
  height: 26px;
  width: 608px;
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: -13px;
  z-index: 1;
}
.group-1,
.textlabel-11 {
  position: relative;
  display: flex;
}
.group-1 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
}
.textlabel-11 {
  width: 68px;
  line-height: 18px;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-1 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-12 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-2,
.line-frame {
  box-sizing: border-box;
}
.line-2 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  opacity: 0.1;
}
.line-frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-13 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-3 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-14 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-4 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-15 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-5 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-16 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-6 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-17 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-7 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-18 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-8 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper6 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-19 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-9 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper7 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-20 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-10 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper8 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-21 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-11 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper9 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-22 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-12 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper10 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-calendar-hour11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  position: relative;
}
.textlabel-23 {
  width: 68px;
  position: relative;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.line-13 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--basic-white);
  box-sizing: border-box;
  opacity: 0.1;
}
.line-wrapper11 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  min-width: 390px;
  max-width: 100%;
}
.group-,
.group-calendar-hour12 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  position: relative;
}
.group-calendar-hour12 {
  flex-direction: row;
  align-items: flex-end;
  row-gap: 20px;
  position: relative;
}
.group- {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-7xl);
  font-size: var(--font-size-sm);
  color: var(--neutrals-cool-gray-500);
  font-family: var(--font-poppins);
}
.calendar-day {
  flex: 1;
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  flex-direction: column;
  padding: 32px var(--padding-23xl) var(--padding-22xl);
  box-sizing: border-box;
  gap: var(--gap-3xl);
  min-width: 489px;
  z-index: 4;
}
.calendar-day,
.frame-div,
.frame-parent7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent7 {
  width: 1153px;
  flex-direction: row;
  gap: var(--gap-31xl);
  margin-top: 58px;
}
.frame-div {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-7xl);
}
.view-rectangle8 {
  height: 576px;
  width: 1153px;
  position: relative;
  backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.textlabel-title7 {
  align-self: stretch;
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-title-wrapper2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
}
.group-options-icon {
  height: 29px;
  width: 29px;
  position: relative;
  object-fit: contain;
  z-index: 1;
}
.frame-parent15 {
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 var(--padding-4xs);
  gap: var(--gap-xl);
}
.skip-union-icon2 {
  height: 111.5px;
  width: 238.6px;
  position: absolute;
  margin: 0 !important;
  top: -40.5px;
  right: 12.3px;
  object-fit: contain;
  z-index: 1;
}
.make-it-happen {
  margin: 0;
}
.add-a-task {
  margin: 0;
  font-size: var(--font-size-sm);
  font-weight: 300;
}
.textlabel-text25 {
  flex: 1;
  position: relative;
  letter-spacing: 0.05em;
  line-height: 24px;
  z-index: 2;
}
.frame-wrapper10,
.skip-union-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.skip-union-group {
  flex: 1;
  position: relative;
}
.frame-wrapper10 {
  align-self: stretch;
  padding: 0 var(--padding-xs);
}
.view-rectangle9 {
  height: 45px;
  width: 291px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-600);
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  display: none;
}
.textlabel-text26 {
  width: 92%;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: var(--font-raleway);
  font-size: var(--font-size-mini);
  background-color: var(--mec-primary-mec-dark-gradient);
  height: 100%;
  position: relative;
  color: var(--color-gray-700);
  text-align: justify;
  display: flex;
  align-items: center;
  padding: 2;
  z-index: 1;
  justify-content: flex-start;
  margin-left: 4px;
}
.textinput-fields,
.textinput-fields1 {
  border: 1px solid var(--color-gray-1000);
  background-color: var(--color-gray-600);
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: row;
  /*align-items: flex-start;*/
  /*justify-content: flex-start;*/
  align-items: center;
  justify-content: space-between;
}
.textinput-fields {
  align-self: stretch;
  padding: var(--padding-mini) var(--padding-9xl);
  z-index: 1;
}
.textinput-fields1 {
  height: 93px;
  width: auto;
  outline: 0;
  box-sizing: border-box;
  padding: var(--padding-mid) var(--padding-9xl);
  resize: none;
}
.textinput-fields1,
.textinput-fields2 {
  align-self: stretch;
  font-family: var(--font-raleway);
  font-weight: 500;
  font-size: var(--font-size-mini);
  color: var(--color-gray-700);
  z-index: 1;
}

.textinput-fields,
.textinput-fields2 {
  gap: 10px;
}


.textinput-fields-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
/*.parent {
  align-self: stretch;
  font-family: var(--font-poppins);
  font-weight: 300;
  font-size: var(--font-size-mini);
  color: var(--basic-white);
}*/
.textinput-fields3 {
  align-self: stretch;
  font-family: var(--font-poppins);
  font-weight: 300;
  font-size: var(--font-size-mini);
  color: var(--basic-white);
}
.frame-parent16 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-gray-500);
}
.textlabel-text27 {
  height: 10px;
  position: absolute;
  margin: 0 !important;
  bottom: 110px;
  left: 28px;
  font-size: var(--font-size-sm);
  line-height: 80px;
  display: flex;
  text-align: justify;
  align-items: center;
  min-width: 70px;
  z-index: 2;
}
.textlabel-24 {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.05em;
  line-height: 24px;
  font-weight: 800;
  font-family: var(--font-raleway);
  color: var(--basic-white);
  display: inline-block;
  min-width: 125px;
  text-align: center;
}
.frame-parent14,
.skip-bmec4 {
  display: flex;
  align-items: flex-start;
}
.skip-bmec4 {
  cursor: pointer;
  border: 1.5px;
  padding: var(--padding-11xs) var(--padding-xl);
  align-self: stretch;
  border-radius: var(--br-xl);
  background: linear-gradient(90deg, #6200ea, #26c5f3);
  background-repeat: no-repeat;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap;
  z-index: 1;
}
.skip-bmec4:hover {
  border: 1.5px;
}
.frame-parent14 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  gap: 65px;
}
.skip-vector-icon1 {
  height: 670px;
  position: relative;
  z-index: 1;
}
.frame-parent13 {
  width: 334px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-23xl);
  min-width: 334px;
  max-width: 100%;
}
.ready-to-tackle-your-first-tas {
  margin: 0;
}
.your-tasks-will {
  margin: 0;
  font-weight: 300;
}
.textlabel-text-txt2 {
  width: 100%;
}
.textlabel-text28 {
  position: absolute;
  top: 268px;
  left: 100px;
  letter-spacing: 0.05em;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 434px;
  height: 50px;
  z-index: 2;
}
.skip-union-icon3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}
.skip-bar-chart-4-bars-icon {
  position: absolute;
  top: 23px;
  left: 46.3px;
  width: 50px;
  height: 50px;
  z-index: 2;
}
.frame-wrapper11,
.skip-union-container {
  position: absolute;
  height: 100%;
  top: 90px; 
  left: 240px;
}
.skip-union-container {
  width: 100%;
  margin: 0 !important;
  right: 0;
  left: 0;

}
.frame-wrapper11 {
  width: 142.7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  
}
.textlabel-text-parent2 {
  align-self: stretch;
  height: 145.5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.group-mytasks,
.group-mytasks-inner {
  display: flex;
  justify-content: center;
}
.group-mytasks-inner {
  width: 100%;
  height: 311px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
}
.group-mytasks {
  width: 100%;
  backdrop-filter: blur(40px);
  border-radius: var(--br-xl);
  background: linear-gradient(rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)),
    #1e293b;
  border: 1px solid var(--color-gray-1000);
  box-sizing: border-box;
  flex-direction: row;
  /* align-items: center; */
  padding: var(--padding-11xl) 43px 48px;
  gap: 60px;
  z-index: 4;
}
.frame-container,
.frame-group {
  display: flex;
  /*align-items: flex-start;
  max-width: 100%;*/
  align-items: center;
}
.frame-group {
  flex: 1;
  flex-direction: column;
  /*justify-content: flex-start;*/
  justify-content: center;
  gap: var(--gap-28xl);
}
.frame-container {
  /*width: 1352px;*/
  flex-direction: row;
  /*justify-content: flex-end;*/
  justify-content: center;
  padding: 0 var(--padding-36xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--basic-white);
  font-family: var(--font-raleway);
}
.frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  /*align-items: flex-end;*/
  /*justify-content: flex-start;*/
  align-items: center;
  justify-content: center;
  gap: 43px;
  width: 100%;
  /*max-width: 100%;*/
}
.view-rectangle10 {
  align-self: stretch;
  height: 422px;
  position: relative;
  border-radius: 0 0 var(--br-41xl) var(--br-41xl);
  background-color: var(--mec-primary-rich-black);
  display: none;
}
.textlabel-title8,
.view-vector-icon1 {
  align-self: stretch;
  position: relative;
  z-index: 1;
}
.view-vector-icon1 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.textlabel-title8 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title10,
.textlabel-title11,
.textlabel-title12,
.textlabel-title13,
.textlabel-title9 {
  align-self: stretch;
  position: relative;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    #5591f5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}
.textlabel-title9 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title10,
.textlabel-title11,
.textlabel-title12,
.textlabel-title13 {
  line-height: 24px;
}
.textlabel-title11,
.textlabel-title12,
.textlabel-title13 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.textlabel-title-parent4 {
  align-self: stretch;
  gap: var(--gap-2xs);
}
.textlabel-title-parent2,
.textlabel-title-parent3,
.textlabel-title-parent4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-title-parent3 {
  align-self: stretch;
  gap: var(--gap-xl);
  font-size: var(--font-size-base);
}
.textlabel-title-parent2 {
  width: 179px;
  gap: var(--gap-6xl);
}
.textlabel-title14 {
  align-self: stretch;
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-title15,
.textlabel-title16,
.textlabel-title17,
.textlabel-title18 {
  align-self: stretch;
  position: relative;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    #5591f5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}
.textlabel-title15 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title16,
.textlabel-title17,
.textlabel-title18 {
  line-height: 24px;
}
.textlabel-title-parent8 {
  align-self: stretch;
  gap: var(--gap-2xs);
}
.textlabel-title-parent6,
.textlabel-title-parent7,
.textlabel-title-parent8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-title-parent7 {
  align-self: stretch;
  gap: var(--gap-xl);
  font-size: var(--font-size-base);
}
.textlabel-title-parent6 {
  width: 179px;
  gap: var(--gap-6xl);
}
.textlabel-title19 {
  width: 179px;
  height: 15px;
  position: relative;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}
.textlabel-title20,
.textlabel-title21,
.textlabel-title22 {
  position: relative;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    #5591f5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}
.textlabel-title20 {
  width: 179px;
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title21,
.textlabel-title22 {
  line-height: 24px;
}
.textlabel-title21 {
  width: 179px;
  display: flex;
  align-items: center;
}
.textlabel-title22 {
  align-self: stretch;
}
.textlabel-title-parent11 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.textlabel-title-parent10 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-xl);
  font-size: var(--font-size-base);
}
.frame-parent18,
.textlabel-title-parent10,
.textlabel-title-parent9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.textlabel-title-parent9 {
  width: 213px;
  flex-direction: column;
  gap: var(--gap-6xl);
}
.frame-parent18 {
  flex-direction: row;
  gap: 55px;
  min-width: 681px;
  max-width: 100%;
}
.skip-newlogo-icon {
  height: 37px;
  width: 62.9px;
  position: relative;
  z-index: 1;
}
.skip-wordmark-icon {
  align-self: stretch;
  height: 24px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.skip-wordmark-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 6.2px;
}
.skip-newlogo-parent {
  width: 299.5px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 12px;
}
.meded {
  font-weight: 300;
}
.cloud {
  font-weight: 200;
}
.textlabel-title23 {
  align-self: stretch;
  position: relative;
  line-height: 25px;
  z-index: 1;
}
.frame-parent19 {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 30px;
  min-width: 320px;
  text-align: right;
  font-size: var(--font-size-mini);
  color: var(--basic-white);
}
.frame-parent17 {
  width: 1155px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
}
.frame-wrapper12,
.view-vector-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frame-wrapper12 {
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
  box-sizing: border-box;
}
.view-vector-parent {
  flex-direction: column;
  justify-content: flex-start;
  gap: 17px;
}
.textlabel-title24,
.view-vector-icon2 {
  align-self: stretch;
  position: relative;
  z-index: 1;
}
.view-vector-icon2 {
  border-radius: var(--br-330xl);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.textlabel-title24 {
  height: 15px;
  line-height: 70px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.textlabel-title-wrapper3 {
  width: 541px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-smi) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.view-elipse {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.skip-social-icon {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 20px;
  height: 20px;
  z-index: 2;
}
.view-elipse-parent {
  height: 42px;
  width: 42px;
  position: relative;
}
.view-elipse1 {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.skip-social-icon1 {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 20px;
  height: 20px;
  z-index: 2;
}
.view-elipse-group {
  height: 42px;
  width: 42px;
  position: relative;
}
.view-elipse2 {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.image-x-icon {
  position: absolute;
  top: 14px;
  left: 14px;
  width: 14px;
  height: 14px;
  object-fit: cover;
  z-index: 2;
}
.view-elipse-container {
  height: 42px;
  width: 42px;
  position: relative;
}
.view-elipse3 {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.skip-social-icon2 {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  z-index: 2;
}
.view-elipse-parent1 {
  height: 42px;
  width: 42px;
  position: relative;
}
.view-elipse4 {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 50%;
  border: 0 solid var(--mec-primary-mec-light-gradient);*/
  border-radius: 50px;
  border: solid 1.5px transparent;
  background:
    linear-gradient( #1e293b, #1e293b) padding-box,
    linear-gradient(90deg, #6200EA 0%, #26C5F3 100%) border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.skip-social-icon3 {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  z-index: 2;
}
.view-elipse-parent2 {
  height: 42px;
  width: 42px;
  position: relative;
}
.frame-parent20,
.frame-parent21,
.frame-wrapper13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.frame-parent21 {
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frame-parent20,
.frame-wrapper13 {
  max-width: 100%;
}
.frame-parent20 {
  width: 1154px;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frame-wrapper13 {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.group-footer,
.my-schedule-first-view-of-new,
.view-vector-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.view-vector-group {
  align-self: stretch;
  gap: 29px;
  max-width: 100%;
  font-size: var(--font-size-xs);
  color: var(--basic-white);
}
.group-footer,
.my-schedule-first-view-of-new {
  background-color: var(--mec-primary-rich-black);
}
.group-footer {
  align-self: stretch;
  border-radius: 0 0 var(--br-41xl) var(--br-41xl);
  padding: 0 0 var(--padding-11xl);
  box-sizing: border-box;
  gap: var(--gap-31xl);
  max-width: 100%;
  z-index: 4;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--mec-primary-cornflower-blue);
  font-family: var(--font-raleway);
}
.my-schedule-first-view-of-new {
  width: 100%;
  position: relative;
  overflow: hidden;
  gap: 100px;
  line-height: normal;
  letter-spacing: normal;
}
.upcomingmeetings-header {
  flex-direction: row;
  display: flex;
  gap: 25px;
}
.mytasks-select-timezone {
  gap: 10px;
  border: 1px solid #FFFFFF;
}
.mytasks-select-legend {
  padding: 0 10px;
  margin: 0 -1.4%;
}
.group-priority {
  position: absolute;
  left: 22px;
}
.skip-arrow {
  position: absolute;
  right: 20px;
}
.textlabel-text-pro {
  width: 92%;
}
.textlabel-text-pre {
  width: 86%;
  margin-left: 20px;
}
.mytasks-skip-arrow {
  position: absolute;
  right: 20px;
}

.textlabel-text29 {
  width: 100%;
  height: 15px;
  margin-left: -25px;
  margin-top: 3px;
}
.textlabel-text10-active-day {
  display: grid;
  padding-right: 80px !important;
  height: 39px;
  width: 44px;
  line-height: 18px;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  /* opacity: 70%; */
  top: 338px;
  left: 1113px;
}
.activity-group-switcher {
  display: flex;
  flex-direction: row
}
.activity-chevron-left,
.activity-chevron-right {
  margin: 10px;
}
.calendar-simple-header {
  display: flex;
  flex-direction: row;
  gap: 90px;
}
.mytasks-group-options {
  width: 100%;
  display: flex;
  flex-direction: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
}
.mytasks-view-vector {
  height: 100%;
  width: 2px;
  display: flex;
}
@media only screen and (max-width: 1080px) {
  .navbar-mec-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .group-right {
    width: 1250px;
  }
  .frame-container,
  .frame-group,
  .group-mytasks,
  .view-vector-icon,
  .my-schedule-highlights,
  .frame-wrapper-3,
  .calendar-simple-parent,
  .group-header {
    width: 100%;
  }
  .skip-substract-icon {
    top: 590px;
  }
  .view-rectangle {
    width: 30%;
    display: flex;
  }
  .calendar-simple {
    display: flex !important;
    gap: 27px;
    padding: 20px 20px;
  }
  .group-header {
    gap: 10%;
    display: flex;
    flex-direction: column;
  }
  .calendar-simple-header {
    gap: 20%;
  }
  .group-calendarelementsday-parent {
    width: 100%;
    gap: 2px 6px;
  }
  .group-upcomingmeetings {
    min-width: 0;
    padding: 20px;
    gap: 65px;
  }
  .textlabel-title-group {
    gap: 20px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .upcomingmeetings-header {
    gap: 7px;
  }
  .textlabel-title2 {
    line-height: 25px;
    width: 70%;
  }
  .upcomingmeetings-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
  .textlabel-text-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .textlabel-text8 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .frame-wrapper4 {
    align-items: center;
    justify-content: center;
    left: 50px;
  }
  .wrapper-skip-union-parent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .group-activity {
    padding: 20px;
    gap: 30px;
  }
  .textlabel-title-container {
    gap: 10px;
    width: 100%;
  }
  .view-stroke-parent {
    align-items: center;
    justify-content: center;
    left: -15px;
  }
  .activity-group-switcher {
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .activity-chevron-left {
    left: 15px;
    position: relative;
  }
  .activity-chevron-right {
    right: 15px;
    position: relative;
  }
  .group-tomorrow-parent {
    gap: 40px;
  }
  .group-tomorrow {
    padding: 40px;
    gap: 72px;
  }
  .group-categories {
    padding: 40px;
  }
  .calendar-day {
    width: 100px;
    padding: 40px;
  }
  .frame-parent11 {
    width: 100%;
  }
  .frame-wrapper9 {
    padding: 0;
    width: 100%;
    flex-direction: row;
  }
  .textlabel-title-parent1 {
    width: 80%;
  }
  .textlabel-title6 {
    display: flex;
    flex-direction: column;
    font-size: large;
    line-height: 35px;
  }
  .frame-parent12 {
    width: 60%;
    gap: 35px;
    top: 5px;
    position: relative;
  }
  .group-switcher-wrapper {
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    left: 37px;
    position: relative;
  }
  .tabbar-segmented-group-mec {
    overflow: hidden;
  }
  .group-current-time-icon {
    width: 430px;
  }
  .group-mytasks {
    gap: 60px;
  }
  .frame-parent15 {
    padding: var(--padding-4xs) 0;
  }
  .mytasks-group-options {
    display: flex;
    flex-direction: flex-end;
  }
  .mytasks-view-vector {
    height: 100%;
    width: 2px;
    display: flex;
  }
  /* .skip-vector-icon1 {
    height: 640px;
    width: 2px;
    z-index: 2;
  } */
}
@media screen and (max-width: 720px) {
  .navbar-mec {
    gap: var(--gap-6xl);
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }
  .frame-parent1 {
    flex-wrap: wrap;
  }
  .calendar-simple-parent {
    gap: var(--gap-6xl);
  }
  .group-tomorrow-parent,
  .line-wrapper {
    min-width: 100%;
  }
  .group-1 {
    flex-wrap: wrap;
  }
  .line-container {
    min-width: 100%;
  }
  .group-calendar-hour {
    flex-wrap: wrap;
  }
  .line-frame {
    min-width: 100%;
  }
  .group-calendar-hour1 {
    flex-wrap: wrap;
  }
  .line-wrapper1 {
    min-width: 100%;
  }
  .group-calendar-hour2 {
    flex-wrap: wrap;
  }
  .line-wrapper2 {
    min-width: 100%;
  }
  .group-calendar-hour3 {
    flex-wrap: wrap;
  }
  .line-wrapper3 {
    min-width: 100%;
  }
  .group-calendar-hour4 {
    flex-wrap: wrap;
  }
  .line-wrapper4 {
    min-width: 100%;
  }
  .group-calendar-hour5 {
    flex-wrap: wrap;
  }
  .line-wrapper5 {
    min-width: 100%;
  }
  .group-calendar-hour6 {
    flex-wrap: wrap;
  }
  .line-wrapper6 {
    min-width: 100%;
  }
  .group-calendar-hour7 {
    flex-wrap: wrap;
  }
  .line-wrapper7 {
    min-width: 100%;
  }
  .group-calendar-hour8 {
    flex-wrap: wrap;
  }
  .line-wrapper8 {
    min-width: 100%;
  }
  .group-calendar-hour9 {
    flex-wrap: wrap;
  }
  .line-wrapper9 {
    min-width: 100%;
  }
  .group-calendar-hour10 {
    flex-wrap: wrap;
  }
  .line-wrapper10 {
    min-width: 100%;
  }
  .group-calendar-hour11 {
    flex-wrap: wrap;
  }
  .line-wrapper11 {
    min-width: 100%;
  }
  .group-calendar-hour12 {
    flex-wrap: wrap;
  }
  .calendar-day {
    padding: var(--padding-2xl) var(--padding-2xl) var(--padding-8xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .frame-parent7 {
    gap: var(--gap-6xl);
  }
  .group-mytasks-inner {
    min-width: 100%;
  }
  .group-mytasks {
    gap: var(--gap-23xl);
    padding-top: var(--padding-xl);
    padding-bottom: 31px;
    box-sizing: border-box;
  }
  .frame-group {
    gap: var(--gap-4xl);
  }
  .frame-parent {
    gap: var(--gap-2xl);
  }
  .frame-parent18 {
    flex-wrap: wrap;
    gap: 27px;
    min-width: 100%;
  }
  .group-footer {
    gap: var(--gap-6xl);
  }
  .my-schedule-first-view-of-new {
    gap: var(--gap-31xl);
  }
  .mytasks-view-vector {
    height: 100%;
    width: 2px;
    display: flex;
  }
  /* .skip-vector-icon1 {
    height: 640px;
    width: 2px;
    z-index: 2;
  } */
}
@media screen and (max-width: 450px) {
  .textlabel-title,
  .textlabel-title1 {
    font-size: var(--font-size-lgi);
    line-height: 56px;
  }
  .frame-parent2 {
    flex-wrap: wrap;
  }
  .calendar-simple {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .textlabel-title2 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .textlabel-title-group {
    gap: var(--gap-lgi);
  }
  .group-upcomingmeetings {
    gap: 16px;
  }
  .textlabel-title3 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .textlabel-text10 {
    font-size: 30px;
    line-height: 12px;
  }
  .textlabel-text-wrapper1 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .view-rectangle-parent {
    flex-wrap: wrap;
    justify-content: center;
  }
  .textlabel-8 {
    font-size: var(--font-size-xl);
    line-height: 19px;
  }
  .textlabel-title4 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .frame-parent8 {
    flex-wrap: wrap;
  }
  .skip-bmec3 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .frame-parent9 {
    gap: var(--gap-9xl);
  }
  .group-tomorrow {
    padding-left: var(--padding-xl);
    padding-top: var(--padding-2xl);
    padding-bottom: var(--padding-5xl);
    box-sizing: border-box;
  }
  .textlabel-title5 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .frame-parent10,
  .group-category,
  .group-category1,
  .group-category2 {
    flex-wrap: wrap;
  }
  .group-categories {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .group-tomorrow-parent {
    gap: var(--gap-4xl);
  }
  .textlabel-title6 {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
  .frame-parent12 {
    flex-wrap: wrap;
    gap: var(--gap-mini);
  }
  .textlabel-title7 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .textinput-fields2 {
    flex-wrap: wrap;
    gap: var(--gap-lgi);
  }
  .frame-parent14 {
    gap: 18px;
  }
  .frame-parent13,
  .group-mytasks {
    gap: var(--gap-2xl);
  }
  .textlabel-title14,
  .textlabel-title19,
  .textlabel-title8 {
    font-size: var(--font-size-base);
    line-height: 56px;
  }
  .my-schedule-first-view-of-new {
    gap: var(--gap-6xl);
  }
}
@media screen and (min-width: 1200px) {
  .frame-container {
    padding-left: var(--padding-8xl);
    padding-right: var(--padding-8xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .textlabel-text10 {
    font-size: 40px;
    line-height: 16px;
  }
  .group-tomorrow-parent {
    flex: 1;
  }
  .frame-parent7 {
    flex-wrap: wrap;
  }
  .frame-parent13,
  .group-mytasks-inner {
    flex: 1;
  }
  .group-mytasks {
    flex-wrap: wrap;
    gap: 85px;
    padding-left: var(--padding-2xl);
    padding-right: var(--padding-2xl);
    box-sizing: border-box;
  }
  .frame-parent18,
  .frame-parent19 {
    flex: 1;
  }
  .frame-parent17,
  .frame-parent20 {
    flex-wrap: wrap;
  }
}
