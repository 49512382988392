.Quetion_bank_list_header {
    display: flex;
    justify-content: space-between;
    background: #d1cece;
    border-radius: 20px;
    padding: 10px 11px;
    align-items: center;
    margin: 0 0 8px 0;
}

.Quetion_bank_list_header_text {
    margin: 28px 0;
    font-size: 36px;
}

.Quetion_bank_list_header_text h4 {
    font-size: 18px;
    color: #fff;
    margin: 0;
}

.Quetion_bank_btn,
.Quetion_bank_btn:focus {
    margin-right: 15px;
    background-color: goldenrod;
    font-size: 14px;
    font-weight: 600;
    border: none;
    color: white;
    border-radius: 20px;
}

.gallery_select p {
    margin-right: 15px;
    background-color: goldenrod;
    font-size: 14px;
    font-weight: 600;
    border: none;
    color: white;
    border-radius: 20px;
    padding: 6px;
    cursor: pointer;
}

.Question_box {
    margin-bottom: 0px;
    padding: 10px 0 10px 10px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    color: black;
}

.Question_box_view {
    border: 1px solid rgb(196, 189, 189);
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.Options_view_box {
    margin-top: 20px;
    border: 1px solid rgb(196, 189, 189);
    padding: 8px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.Options_view {
    color: black;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.class-pag {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    list-style: none !important;
}

.modal-content {
    border: none !important;
}

.Answer_description_box {
    background: goldenrod;
    color: #fff;
    font-weight: 600;
    padding: 6px 14px 6px 14px;
    border-radius: 20px;
}

.Answer_description_box p {
    margin: 0;
}

input[type="file"] {
    display: none !important;
}

.Question_answer_box {
    margin: 25px 12px;
}

.description_heading {
    font-size: 18px;
    margin: 10px 0;
}

.container-loginComp {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column;
    width: 100% !important;
    min-height: 100vh !important;
    padding: 0 !important;
    position: relative !important;
    z-index: 999 !important;
    font-family: "Raleway", sans-serif !important;
    color: #ffffff !important;
  }

.sectionContainer {
    background: linear-gradient(180deg, rgba(85, 145, 245, 0) 0%, rgba(114, 239, 221, 0.25) 49%, rgba(85, 145, 245, 0.5) 100%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.questionNum{
    font-size: 20px;
    font-weight: 900;
    line-height: 40px;
}

.question{
    font-size: 25px;
    font-weight: 600;
    line-height: 40px;
}

.dialogContainer {
    width: 100%;
    height: 100%;
}

.dialogBox {
    width: 481px;
    height: 287px;
    background-color:#334155;
    padding: 30px
}

.dialogTextCon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px
}

.dialogHeading {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #fff;
    font-family: 'Raleway', sans-serif !important;
}

.textDec {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #fff;
    font-family: 'Raleway', sans-serif !important;
}

.textDec1 {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #fff;
    font-family: 'Raleway', sans-serif !important;
    
}

.MuiPaper-elevation24 {
    box-shadow: none !important;
}

.btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.leaveBtn {
    width: 190px;
    height: 32px;
    color: #94A3B8;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background-color: #050D1566;
    cursor: pointer;
    font-weight:bold;
    font-family: 'Raleway', sans-serif !important;
}

   
.cancelBtn {
    width: 190px;
    font-family: 'Raleway', sans-serif !important;
    font-weight:bold;
    height: 32px;
    color: rgba(114, 239, 221, 1);
    border-radius: 20px;
    background: rgba(114, 239, 221, 0.2);
    border-image-source: linear-gradient(180deg, rgba(5, 13, 21, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
}

.iconBox {
    display: flex;
    justify-content: space-between;
}

.leaveBorder {
    border: 1px solid rgba(114, 239, 221, 1);
    padding: 10px;
    border-radius: 50%;
}

.rightAnswer {
    border: 1px solid green !important;
}