.container-header {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column;
  width: 100% !important;
  min-height: 70px !important;
  padding: 0 !important;
  position: relative !important;
  /* z-index: 999 !important; */
  font-family: "Raleway", sans-serif !important;
  color: #ffffff !important;
  /* overflow-x: hidden !important; */
  background-color: transparent !important;
}
strong {
  display: inline-block;
}
.preview-font span{
  display:contents !important;
}

.container p,
.container h1,
.container h2,
.container span,
.container button {
  font-family: "Raleway", sans-serif !important;
}

.headerAll {
  width: 100%;
  height: auto;
  /* position: fixed; */
  top: 0;
  left: 0;
  display: flex;
  /* justify-content: space-between; */
  z-index: 10;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  /* background: rgb(23, 33, 48); */
  background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5));
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 20px;
  width: 28%;
  align-items: center;
  border: 1px solid gray;
  height: 186px;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  width: 70%;
  border: 1px solid gray;
  padding: 20px;
  height: 186px;
}

.headerAll::before {
  content: "";
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background-color: #272e3450; */
 
}

.mainQueSimulateContainer{
  display: flex;
  width: 100%;
  margin: 10px 0;
}
.headerMain{
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  z-index: 1;
  position: relative; 
  background-color: #0F161E; 
  
}

.logoTop {
  width: 145.93px;
  height: 67.75px;
  padding: 0px;
  object-fit: contain;
}

.searchContainer {
  display: flex;
  align-items: center;
  margin: 20px 0 0;
  width: 30%;
  justify-content: flex-end;
}
.simulateQueBtn {
padding: 2px;
overflow: hidden;
width: 100%;
height: 32px;
border-radius: 100px;
display: inline-block;
margin: 24px 14%;
position: relative;
}

.simulateQueBtn::before {
content: "";
position: absolute;
width: 70%;
height: 32px;
inset: 0;
padding: 2px;
border-radius: 100px;
background: linear-gradient(to right, #6200ea, #26c5f3);
-webkit-mask: 
   linear-gradient(#fff 0 0) content-box, 
   linear-gradient(#fff 0 0);
        mask: 
   linear-gradient(#fff 0 0) content-box, 
   linear-gradient(#fff 0 0);
-webkit-mask-composite: xor;
        mask-composite: exclude; 
}

.usrNameTxt {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background: none;
}
.header {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 46px;
  border-radius: 50px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
}
.showProfileLogout {
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  width: 160px;
  height: 60px;
  margin-top:10px;
  border-radius: 50px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
}
.showProfile {
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  width: auto;
  height: 46px;
  /* padding: 5px; */
  border-radius: 50px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
}
.subTextProfile{
  color: rgba(255, 255, 255, 0.3);
  font-size: 10px;
}
.profileLetter {
  background: rgba(255, 255, 255, 0.2);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.medBodySubText {
  font-size: 18px;
  font-weight: 600 !important;
  color: white;
  margin-top: 20px !important;
}


.textContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin-left: 5px;
}

.searchBtn {
  background: rgba(255, 255, 255, 0.1);
  width: 46px;
  height: 46px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
}
.exploreCard {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 5%
}
.CradBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;  
}
.QuestionsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  
}
.swipedata{
  width: 100%;
}
.cardMapContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #172130;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-bottom: 20px;
  overflow: clip;
  /* padding-left: 20px; */
}

.graphContainer {
  width: 100%;
  height: 320px;
  margin: 1% 0px 2%;
  border-radius: 20px;
  /* border: 1px solid transparent; Assuming you want to set a solid border */
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  overflow: hidden;
  padding: 25px;
  background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5));
  /* opacity: 0; Assuming you meant 'opacity: 0' rather than 'opacity: '0px'' */
}
.cardMainContainer {
  padding: 20px;
  background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5));
  width: 75%;
  margin: 0 auto 5%;
  border-radius: 20px;
  border: 1px solid transparent; /* Adjust border according to your needs */
  /* opacity: 0; Assuming you meant 'opacity: 0' rather than 'opacity: '0px'' */
}

.displayMenu {
  width: 56%;
}

.logoContainer {
  width: 14%;
}

.displayMenuText{
  width: 100px;
}
.cardMapContainer .cardTitle {
    font-size: 20px !important;
    font-weight: 900;
    color: #FFFFFF;
}
.knowledgeStatsContainer {
  width: 100%;
  height: auto;
  margin: 1% 0px 2%;
  border-radius: 20px;
  padding: 25px;
  background-color: #121c28;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  padding-bottom: 40px;
}
.knowledgeStatsContainer ::before {
  width: 100%;
  height: 100%;
  
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 10%;
  border-radius: 20px;
  border: 1px solid transparent;
  position: absolute;
}
.QueNewContainer{
  width: 75%;
  /* height: 228px; */
  margin: 5% auto 5%;
  border-radius: 20px;
  /* border: 1px solid transparent; Assuming you want to set a solid border */
  padding: 2%;
  background-color: #1E293B;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
}
.statsContainer {
  width: 75%;
  /* height: 228px; */
  margin: 5% auto 5%;
  border-radius: 20px;
  /* border: 1px solid transparent; Assuming you want to set a solid border */
  padding: 2%;
  background-image: url('../assets/Radialbackground.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #1E293B;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
}
.statsContainer ::before {
width: 100%;
height: 100%;
content: "";
top: 0;
left: 0;
z-index: 1;
opacity: 10%;
border-radius: 20px;
border: 1px solid transparent; /* Assuming you want to set a solid border */
background-image: url('../assets/Radialbackground.png');
background-repeat: no-repeat;
background-position: center center;
position: absolute;
}

.upcoming {
width: 320px;
height: 416px;
margin-left: 63px;
border-radius: 20px;
padding: 20px;
background-image: url('../assets/Radialbackground.png');
background-repeat: no-repeat;
background-position: center center;
background-color: #1E293B;
border: 1px solid rgba(255, 255, 255, 0.05);
position: relative;
}

.upcoming ::before{
content: "";
top: 0;
left: 0;
z-index: 1;
opacity: 10%;
background-image: url('../assets/Radialbackground.png');
background-repeat: no-repeat;
background-position: center center;
position: absolute;
}
.quePopUp {
display: flex;
align-items: center;
justify-content: center;
position: relative;
}
.popUpMain {
width: 501px;
  height: 136px;
  border-radius: 5px 20px 20px 20px;
  padding: 20px;
  background-color: rgba(5, 13, 21, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: absolute;
  display: none;
  left: 50%;
  top: 40px;
  z-index: 12;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.popUpMain p{
  font-family: Poppins, sans-serif!important;
}
.quePopUp:hover .popUpMain{
display: block;
}
.Glow {
width: 311px;
height: 311px;
left: calc(50% - 155px);
top: calc(50% - 155px);
position: absolute;
opacity: 58%;
}
.CircleContainer {
background-color: #0a1321;
width: max-content;
/* width:393px; */
height: 393px;
position: relative;
border: 6px solid rgba(255, 255, 255, 0.05);
border-radius: 100%;
/* opacity: 0.58; */
aspect-ratio: 1/1;
}
.knowledgeLineContainer {
border-bottom:4px solid #E6D4D433; 
width:393px;
  height:151px;
  position: relative;
  border-radius:0 0 240px 50%/10px;
  box-shadow: rgba(174, 174, 174, 0.32) 0px 0px 0px 0px, rgba(236, 230, 230, 0.12) 0px 2px 0px;
    margin-top: 26%;
  
}

.medMainContainer {
/* background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)); */
background: rgb(18,28,40);
width: 75%;
margin: 0 auto 5%;
border-radius: 20px;
border: 1px solid transparent;
padding: 2%;
}

.overall_card {
  margin-left: 35% !important;
}

.medMainContainer .overall_card {
  margin: -12px !important
}
.medMainContainer .overall_card > div > div {
  max-width: none !important;
  min-width: 0px !important;
}
.medMainContainer button {
  border: none !important
}
.medMainContainer .CradBox .cardMapContainer > div { padding: 0px !important; }
.medMainContainer .CradBox .cardMapContainer > div > div > div > div > p {
  font-family: "Raleway", sans-serif!important;
}
.medMainContainer .CradBox .cardMapContainer > div:nth-child(2) > div {
  margin-top: 30px !important
}
.medMainContainer .CradBox .cardMapContainer > div:nth-child(2) > div > div > p {
  font-family: "Poppins", sans-serif!important;
}
.medMainContainer .CradBox .cardMapContainer > div:nth-child(2) > div:nth-child(2) > .course_desc > p {
  font-family: "Raleway", sans-serif!important;
}
.course-page .preview-font {
  font-family: "Poppins", sans-serif!important;
}
.medMainContainer .uncheck-cls {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.medMainContainer .checked-cls {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.medMainContainer11 {
  /* background: linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5)); */
  /* background: rgb(18,28,40); */
  width: 90%;
  margin: 0 auto 5%;
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 2%;
  }

.spanSimulateBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
  background-color: #1E293B;
  border-radius: 100px;
  color: white;
  text-transform: capitalize;
}

.simulateBtn{
  width: 20%;
  height: 31px;
  padding: 2px;
}
.addNewTask {
width: 320px;
height: 416px;
margin-left: 63px;
border-radius: 20px;
padding: 20px;
background-color: #1E293B;
border: 1px solid rgba(255, 255, 255, 0.05);
position: relative;
}
.UpperContainer {
justify-content: space-between;
display: flex;
flex-wrap: initial !important;
width: 75% ;
margin: 0 auto;
}
.upperKnowledgeContainer {
/* justify-content: space-between; */
display: flex;
flex-wrap: initial !important;
width: 75% ;
margin: 0 auto;
}
.createdTask {
width: 100%;
background: #35107a;
height: 70px;
border-radius: 20px;
border: 0px solid rgba(255, 255, 255, 0.05);
margin-bottom: 12px;
padding:10px;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
position: relative;
}
.newTask {
  width: 320px;
  height: 416px;
  margin-left: 63px;
  border-radius: 20px;
  padding: 20px;
  background-image: url('../assets/Radialbackground.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #1E293B;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
}

.newTask ::before {
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 10%;
  background-image: url('../assets/Radialbackground.png');
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
}
.newTaskQu {
  width: auto;
  height: 416px;
  border-radius: 20px;
  padding: 20px;
  background-image: url('../assets/Radialbackground.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #172130;
  border: 3px dashed rgba(255, 255, 255, 0.05);
  position: relative;
  flex-basis: 31.33%;
  margin: 0 1%;
}
.quesbanks{
  flex-basis: 31.33%;
  margin: 0 1%;
}
.cardtext{
  font-family: Poppins, sans-serif!important;
}
.newTaskQu ::before {
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 10%;
  background-image: url('../assets/Radialbackground.png');
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
}
.react-swipeable-view-container{
  cursor: pointer;
}
.paginationdots{
  width: 100%;
}
.paginationdots button{
  min-width: auto;
  padding: 4px;
}
.positionFifth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 302px;
  right: 40px;
}
.positionFourth {
  position: absolute;
  top: 302px;
  left: 40px;
}
.positionThird {
  position: absolute;
  top: 110px;
  right: -40px;
}
.positionSecond {
  position: absolute;
  top: 112px;
  left:-20%
}
.positionKnowledgeSecond {
  position: absolute;
  top: 115px;
  left:-3%
}
.positionKnowledgeThird {
  position: absolute;
  top: 115px;
  right: -10px;
}
.secondBtnTitle {
  width: 135px;
  text-align: center ;
  color: #FFFFFF;
  margin-left: 10px;
}
.secondBtnKnowledgeTitle {
  width: 135px;
  text-align: center ;
  color: #FFFFFF;
 
}
.thirdBtnTitle {
  width: 100px;
  text-align: center ;
  color: #FFFFFF;
  margin-left: 20px;
}

.backShadowFirst {
  position: absolute;
  left: 50%;
  margin-left: -37px;
  /* background: "radial-gradient(51% 51% at 42.16% 40%, #FFFFFF 0%, #72EFDD 18%, #0F172A 100%)", */
}

.knowledgeCenter {
  top: -50px;
}

.catalogue {
  top: 100px;
}

/* .backShadowFirst::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 311px;
  height: 311px;
    background: linear-gradient(135deg, rgba(114, 239, 221, 0.2) 0%, rgba(38, 197, 243, 0.2) 100%);
} */

/* For the center circle with a more intense glow */
.center-circle {
animation: glow-animation 2s infinite alternate;
opacity: 40%;
}

.centerIcon{
  font-size: 14px !important;
  font-weight: 800 !important;
  text-align: center;
  margin-left: -30px !important;
  margin-top:20px !important
}
.leftIcon{
  font-size: 12px !important;
  text-align: center;
}
.rightIcon{
  font-size: 12px !important;
  text-align: center;
}
.leftIconSize{
  height: 97%;
  width: 98%;
  background-color: black;
  object-fit: contain;
  padding: 12px;
  border-radius: 50%;
}
.rightIconSize{
  height: 94%;
  width: 96%;
  background-color: black;
  object-fit: contain;
  padding: 12px;
  border-radius: 50%;
}
.centerIconSize{
  height: 30px;
  width: 30px;
}

/* Glow effect animation */
@keyframes glow-animation {
0% {
  box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px cyan, 0 0 25px cyan, 0 0 30px cyan, 0 0 35px cyan, 0 0 40px cyan;
}
100% {
  box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px cyan, 0 0 25px cyan, 0 0 30px cyan, 0 0 35px cyan, 0 0 40px cyan, 0 0 45px cyan;
}
}

.commonBox{
  width: 90px;
  height: 90px;
  background-color: rgba(5, 13, 21, 1);
  display: flex;
  border: 2px solid grey;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 50%;
  z-index: 100;
  position: relative;
}
.marketplaceBox{
  width: 90px;
  height: 90px;
  background-color: rgba(5, 13, 21, 1);
  display: flex;
  border: 2px solid grey;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 100;
  position: relative;
}

.smallBtn {
  width: 60px;
  height: 60px;
  background-color: rgba(5, 13, 21, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 50%;
  z-index: 100;
  position: relative; 
}  

.firstBtn {
  margin-left: 0px;
  border-width: 2px; /* Specific border width for the first button */
  margin-bottom: 10px;
}

.secondBtn {
  margin-left: 32px;
  margin-bottom: 10px;
  
}

.thirdBtn {
  margin-left: 25px;
  margin-bottom: 10px;
}
.welcomeTxt {
  color: white;
  font-weight: 500;
  /* border-bottom: 1px solid grey; */
  margin: 0 auto 30px;
  /* padding-bottom: 30px; */
  width: 75%;
}

.subMenuText {
  background: linear-gradient(89.86deg, #FFFFFF 0%, rgba(114, 239, 221, 0.5) 50%, rgba(38, 197, 243, 0.5) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900 !important;
}
.taskContainer {
  background-color:#050D15;
  padding: 3% 100px 3% ;
  border-radius: 0 0 50px 50px;
  position :relative;
}

.questionImg {
  position: absolute;
 bottom: -20px;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 40%;
  right: 60px;
  background: linear-gradient(90deg, #6200EA 0%, #26C5F3 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.calenderStyle {
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: center;
}

.medContainer {
  background: #172130;
  width: calc(100% - 20px);
  border-radius: 20px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 1px solid #FFFFFF0D;
  height: 150px;
  margin: 10px
}

.medWrapper {

}

.img-container{
  flex-basis: 30%;
}
.grapharea{
  flex-basis: 65%;
  margin-left: 5%;
  max-width: 65%;
}
.graphstart, .graphend{
  color: #fff;
  opacity: .23;
  font-size: 13px;

}
.chartgraph{
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
 
}
#gauge-chart5 text{
  display: none;
}
.graphvalue{
  display: flex;
  justify-content: center;
  font-family: Poppins, sans-serif!important;
}
.graphvalue span{
  font-family: Poppins, sans-serif!important;
  font-weight: 700;
}
.hourchart span{
  font-family: Poppins, sans-serif!important;
}
.hourchart{
  z-index: -1;
}
.graphtext, .graphtext span, .cardinfo{
  font-family: Poppins, sans-serif!important;
}
.mr-10{
 margin-bottom: -5px;

}
.graphvalue span{
  color: #fff;
  background-color: #39414b;
  font-size: 16px;
  border-radius: 100%;
  margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-right: -1px;
}
#gauge-chart5 {
  width: 100%;
}
#gauge-chart5 svg{
max-width: 100%;
width: 100%;
}
#gauge-chart5 div:first-of-type{
  max-width: 100%;
}
.taskImg{
  width: 20px;
  height: 20px;
  margin-right: 10px; 
  margin-left: 5px;
}
.taskSection{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
 
}
.taskMainSection{
  height: 270px;
  overflow-y: auto;
  margin-bottom: 15px;
}
.p-2{
  padding: 2px;
}
.task-img{
  width: 20px;
}
.titleTask{
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.taskdeadline{
  font-family: Poppins, sans-serif !important;
  opacity: .8;

}
.greenCircle{
  background-color: #34D399;
  outline: 2px solid rgba(52, 211, 153, .3);
}
.yellowCircle{
  background-color: rgb(245, 158, 11);
  outline: 2px solid rgba(245, 158, 11, .3);
}
.redCircle{
  background-color: rgb(248, 113, 113);
  outline: 2px solid rgba(248, 113, 113, .3);
}
.greenCircle, .yellowCircle, .redCircle{
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  outline-offset: 1px;
}
.skipCircle{
  position: absolute;
  right: 20px;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 50%;
}
.task-title{
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-width: 90%;
}
.hourchart span{
  position: relative;
}
.hourchart span::after{
  content: "";
  width: calc(100% - 30px);
  height: 1px ;
  background-color: #8D8D8D;
  display: block;
  position: absolute;
  top: 50%;
  left: 30px;
  opacity: .3;
}
.cardMapContainer .enrollbtn{
  text-transform: capitalize;
  margin-top: 10px;
}
.enrollbtn{
  font-weight: 800!important;
}
.quebankbtns{
  font-weight: 800!important;
  letter-spacing: 0.7px!important;
  height: 32px;
  padding: 2px;
}
.cardMapContainer::-webkit-scrollbar {
  width: 10px;
}



.active_explore {
  font-size: larger;
  color: #fff;
  font-weight: bold;
  position: relative;
}
.no_active {
  font-size: large;
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold
}

.active_explore::after {
  content: '';
  display: block;
  width: 100%; 
  height: 2px; 
  background: linear-gradient(to right, rgb(98, 0, 234) 0%, rgb(38, 197, 243) 100%); 
  position: absolute;
  top: 30px;
}

.disabled-image {
  pointer-events: none;
  opacity: 0.5; 
}
.explore-sort-items li{
  position: relative;
  display: block;
}
.explore-sort-items li:after{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
  height: 2px;
  background-image: url('../assets/border.svg');
  margin-left: -10px;
  
}

.explore_search {
  outline: none;
  border: none;
}
.explore_search:focus {
  outline: none;
  border: none;
}
.explore_search::placeholder {
  color: #fff
}

.hide-scrollbar {
  scrollbar-width: none; 
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; 
}
.course_desc::-webkit-scrollbar, .taskMainSection::-webkit-scrollbar{
  background-color: transparent;
  width: 5px;
}

.course_desc::-webkit-scrollbar-thumb, .taskMainSection::-webkit-scrollbar-thumb {
  background: #ababab;
  width: 4px;
  border-radius: 20px;
}

.overall_card > div > div {
  max-width: 400px !important;
  min-width: 400px !important;
}
.cardimagedata {
  height: 210px !important;
}
.overall_card::-webkit-scrollbar{
  background-color: transparent;
  width: 5px;
  height: 5px;
}

.overall_card::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 20px;
}

.overall_card_journy::-webkit-scrollbar{
  background-color: transparent;
  width: 5px;
  height: 5px;
}

.overall_card_journy::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 20px;
}
.cardinfo{
  opacity: .7;
  font-weight: 300;
}
.googlemeet{
  color: #fff;
}
.selectTimeRange{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}
.selectTimeRange .MuiSelect-select{
padding: 5px 20px;
font-size: 14px;
padding-right: 20px!important;
text-transform: none;
}
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon , .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.knowledgeStatsContainer .MuiSvgIcon-root
{
  display: none!important;
}
.chartmsg{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  color: #fff;
  font-size: 12px;
  opacity: 0.8;
}

.filter_header {
  padding: 16px 12px;
  border-radius: 20px 20px 0px 0px;
  background-image: url("../assets/view_rectangle.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.filter_background {
  padding: 24px 47px;
  border-radius: 0px 0px 20px 20px;
  background-image: url("../assets/view_filter_body_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 -20px;
}

.under_border {
  width: 100%;
  height: 2px;
  background-image: url('../assets/underLine.svg');
  background-repeat: no-repeat;
  background-position: center center;
  margin: 6px -7px;
}

.filter_background .row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.column {
  flex: 1 1 33.33%; 
  max-width: 33.33%; 
  box-sizing: border-box; 
  padding: 5px; 
}
.filterText {
  font-weight: 700;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.checkWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: rgba(255,255,255,0.1);
  padding: 6px 10px;
  border-radius: 16px;
  justify-content: center;
}
.cattext {
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
.levelText {
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
.filter_background > p {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.container_row {
  display: flex;
  flex-wrap: nowrap; 
  overflow-x: auto;
  scroll-snap-type: x mandatory; 
  padding: 10px;
}

.container_Column {
  min-width: 33.33%; 
  max-width: 33.33%; 
  flex: 0 0 33.33%; 
  scroll-snap-align: start; 
  margin-right: 10px;
}

.chartheader{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.totalgraph{
   position: relative;
  width: 100%;
  height: 139px;
}
.pagination-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 11;
  bottom: 17px;
  margin: 20px auto 0;
  width: 100%;
}

.dot {
  height: 7px;
  width: 7px;
  margin: 0 5px;
  background-color: #717171;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #bbb;
}
.contentgraph{
  display: flex;
  padding: 10px;
}
.leftgraph{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 20px;
  width: 40%;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.1);
  height: 186px;
}
.rightgraph{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 20px;
  width: 58%;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  height: 186px;
  margin-left: 2%;
}
.donut-chart {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
}

.donut-hole {
  width: 80px;
  height: 80px;
  background: #1E293B;
  border-radius: 50%;
  top: 50%;
  left: 48%;
  font-size: 13px;
  transform: translate(-50%, -50%);
  z-index: 1;
  position: relative;
}

.donut-hole-2 {
  width: 39px;
  height: 39px;
  background: #1E293B;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
}

.donut-hole-3 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
}

.donut-data {
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 10px;
  color: #fff;
  font-family: Poppins, sans-serif!important;
  font-weight: 400;
}

.donut-slice {
  position: absolute;
  width: 100%;
  height: 100%;
  clip: rect(0, 150px, 150px, 75px);
  border-radius: 50%;
}
.piechartgraph{
  display: flex;
  width: 100%;
}
.piedata{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  margin-left: 10%;
}
.correctdata p{
  font-size: 14px;
  position: relative;
  color: #fff;
  font-family: Poppins, sans-serif!important;
}

.dotcorrect, .dotincorrect{
  width: 9px;
  height: 9px;
  border-radius: 100%;
  display: inline-block;
  left: -16px;
  top: 3px;
  position: absolute;
}
.dotcorrect{
  background-color: #72EFDD;
}
.dotincorrect{
  background: #5591F5;
}
.correctdata{
  text-align: right;
  margin: 10px;
}
.innersection {
  display: flex;
  margin-bottom: 4px;
}
.widthfill{
  border-radius: 10px;
}
.graphareabar{
  width: 50%;
  height: 20px;
  margin-left: 10px;
  padding: 4px 0;
}

.totalque span{
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}

.donutdata{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.errorsname{
  width: 50%;
  text-align: left;
  color: #fff;
position: relative;
display:flex;
}
.horizontalbar{
  margin-top: 15px;
}
.errorspan{
  color: #fff;
  width: auto;
  opacity: .8;
  font-size: 13px;
  font-family: Poppins, sans-serif!important;
  text-transform: capitalize;
}
.errorsname::after{
  content: "";
  background-color: #fff;
  height: 1px;
  width: 90%;
  opacity: .1;
  margin: 10px 5%;
}

label.MuiFormControlLabel-root{
  position: absolute;
  top: 10px;
  right: 114px;
}
.errornum{
  font-size: 15px;
  color: #fff;
  width: 35px;
  text-align: right;
}
.graphheader{
  color: white;
  font-size: 12px;
  opacity: 0.8;
  font-weight: 700;
  text-transform: uppercase;
}
.popularCat{
  width: 100%;
}
.catHeading{
  color : #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.sectionnumber{
  color:#72EFDD;
 display: flex;
 justify-content: center;
 align-items: center;
  border-radius: 10px;
  border: 1px solid #72EFDD;
  width: 40px;
  height: 20px;
}
.sectionnumber span{
  color:#72EFDD;
  font-family: Poppins, sans-serif!important;
  font-size: 12px;
}

.popularCat h6{
  color : rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}
.popularinner{
  display: flex;
  margin-top: 15px;
}
.populargraph{
  display: flex;
  padding: 20px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  height: 186px;
  border-radius: 20px;
  width: 100%;
}
.uparrow{
  height: 100%;
  margin-right: 15px;
  width: auto;
}
.popularimg, .popularimg2{
  position: absolute;
  width: 40px;
}
.popularimg{
  left: 0;
}
.popularimg2{
  left: 50%;
}
.rightbest{
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 130px;
}
.uparrow-sm{
  position: absolute;
  top: 10px;
  height: 10px;
  left: 7px;
}
.downarrow-sm{
  position: absolute;
  bottom: 10px;
  height: 10px;
  left: 7px;
}
.popular-heading{
  display: flex;
  width: 50%;
  margin-bottom: 13px;
  font-family: Poppins, sans-serif!important;
  position: relative;
  align-items: center;
}
.popular-heading p{
  color: #fff;
  font-size: 14px;
  opacity: .7;
  font-weight: 500;
  letter-spacing: .4;
  max-width: 190px;
}
.popular-heading img{
  width: 15px;
  margin-right: 15px;
  margin-left: 35px;
  height: 15px;
  object-fit: cover;
}
.section-cat{
  width: 22px;
  height: 22px;
  background-color: #37465E;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif!important;
  position: absolute;
  right: 20px;
}
.section-cat span{
  color:#fff;
  font-size: 12px;
  font-family: Poppins, sans-serif!important;
}
.linechart{
  position: absolute;
  left: -10px;
  top: 40px;
}

.MuiPopover-root .MuiMenu-list, .MuiPopover-root .MuiMenuItem-root{
  background-color: #1C2632;
  color: #fff;
}
.MuiPopover-root ul.MuiList-root{
  padding: 0 0!important;
  background-color: #1C2632;;
}
.MuiPopover-root .MuiMenuItem-root{
  padding: 10px 25px;
  font-size: 14px;
  font-family: Poppins, sans-serif!important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  min-width: 130px;
}
.MuiOutlinedInput-notchedOutline, .css-igs3ac, .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
 border-color: transparent!important;
}
.MuiPaper-root.MuiPaper-rounded{
  border-radius: 20px;
  min-width: 130px!important;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background-color: transparent!important;
  color: transparent!important;
}
.MuiPopover-root .Mui-selected{
  font-weight: 600;
  background: linear-gradient(90deg, rgba(45, 0, 247, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%)!important;
}
.MuiMenuItem-root:hover{
  background: linear-gradient(90deg, rgba(45, 0, 247, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%)!important;
}
.medRightImg {
  transform: scaleY(-1);
  margin-top: -22px;
}

.font-family-p {
  font-family: Poppins, sans-serif!important;
}

.entityone, .entitytwo{
  position: absolute;
}
.entitytwo{
  right: 5px;
}
.entityone{
  right: calc(50% + 5px) 
}
.maingraphsection{
  cursor: grabbing;
  position: relative;
  z-index: 99;
  padding-top: 50px;
}

.statsheading{
  position: absolute;
  width: calc(100% - 50px);
}

.MuiSwitch-root {
  width: 66px!important;
  height: 33px!important;
  padding: 0px!important;
}

.MuiSwitch-thumb {
  width: 30px!important;
  height: 25px!important;
  background-color: #64748B!important;
}

.MuiSwitch-track{
  border-radius: 30px!important;
  background-color: rgba(255, 255, 255, 0.1)!important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #64748B!important;
}

.MuiSwitch-switchBase {
  top: -5px!important;
}

.MuiSwitch-input {
  left: 0!important;
  width: 100%!important;
}

.donutdata p{
  color: #fff;
  font-size: 13px;
}

.donutdata .totalque{
  color: #fff;
  font-weight: 800;
  font-size: 14px;
}
.hourDiv{
  z-index: -99;
}
.grapharea .content{
  background-image: none;
  background-color: transparent;
}
.topbestlist{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  height: 30px;
}
.topbestimg img{
  width: 25px;
}
.topbestlist p{
  color: #fff;
  max-width: 180px;
  font-size: 14px;
  opacity: .8;
  font-family: Poppins, sans-serif!important;
  line-height: 20px;
}
.topbestimg{
  list-style-type: none;

  width: 35px;
}
.topbestimg li{
  height: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.topbestarea{
  display: flex;
}

.empty-explore {
  background-image: url('../assets/skip_union.png');
  background-repeat: 'no-repeat';
  Width: 256px;
  Height: 270px;
  position: relative;
}
.centered-element {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headerData{
  display: flex;
  background-color: #050E15;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  color: #fff;
  padding: 40px 10%;
  align-items: center;
}
.subheader{
  margin-bottom: 50px;
}
.leftheader{
  width: 30%;
  display: flex;
  align-items: flex-end;
}
.leftheader h4{
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 300;
}
.leftheader p{
  font-size: 24px;
  font-weight: 900;
}
.rightheader{
  width: 70%;
}
.rightheader img{
  width: 16px;
  margin-right: 5px;
}
.journeyData{
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
.journeyData p{
  font-size: 13px;
  margin: 0 10px 10px;
  font-family: Poppins, sans-serif!important;
  font-weight: 400;
  letter-spacing: .5;
  display: flex
}
.journeytext{
  font-size: 14px;
  opacity: .8;
  text-align: right;
  width: 100%;
}
.journeybackload{
  width: 30px;
  justify-content: center;
  border: 0px solid #fff;
  border-radius: 50%;
  height: 30px;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.journeyback{
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 0px solid #fff;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
}
.journeyback img{
  width: 100%;
  padding: 4px;
}
.cardParent{
  /* width: 100%; */
  /* overflow-x: hidden; */
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 120px;
}

.boxJourney{
  /* margin-left: 37.5%; */
  position: relative;
  width: 100%;
  min-height: 420px;
  padding: 0px;
}
.borderJourney {
  outline: 3px solid #72EFDD;
  outline-offset: 3px;
}
.comingsoon{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:99;
}
.comingsoon p{
  color: rgba(255, 255, 255);
  font-size: 22px;
  font-weight: 700;
}
.boxJourney:first-of-type .comingsoon{
  display: none;
}

.cardParent .getStartbtn{
  color: white;
  position: absolute;
  background: linear-gradient(90deg, rgb(98, 0, 234) 0%, rgb(38, 197, 243) 100%);
  border-radius: 20px;
  height: 32px;
  overflow: hidden;
  letter-spacing: 0.7;
  text-transform: capitalize;
  margin-bottom: 62px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  padding: 4px 40px;
  white-space: nowrap;
}
.cardParent .getStartbtn:first-of-type{
  display: block;
}
.boxJourney .percentCircle{
  display: none;
}
.boxJourney:first-of-type .percentCircle{
  display: inline-block;
}
.cursorpointer{
  cursor: pointer;
  margin-left: -10px !important;
}
.medMainContainer > div + div {
  margin-right: -10px !important;
}
.listMenu{
  background-color: rgba(5, 13, 21, 1);
  color: #fff;
}
.navHeader{
  background-color: transparent!important;
  width: 60px!important;
  position: absolute!important;
 right: auto;
 left: 10px;
 border-radius: 50%;
}

.MuiDrawer-paper.MuiPaper-root{
  position: relative;
  top: 0px;
  background-color: rgba(5, 13, 21, 1);
  color: #fff;
  height: auto;
  overflow-x: hidden;
  padding: 50px 0px 30px;
  border-bottom-right-radius: 100px;
  margin-bottom: 20px;
}
.scoreDrawer{
  position: relative;
}
.scoreDrawer .MuiDrawer-paper.MuiPaper-root{
  height: 100%;
  position: absolute;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
  max-height: inherit;
}
.MuiDrawer-root.MuiDrawer-docked .MuiIconButton-label{
color: #fff;
}

.unitareaDrawer .MuiDrawer-paper.MuiPaper-root{
  background-color: transparent;
  padding: 0px 0px 0px;
  border-color: transparent;
}

.unitareaDrawer .MuiDrawer-paper.MuiPaper-root .mainList{
  background-color: rgba(5, 13, 21, 1);
  padding: 50px 0px 50px;
  border-bottom-right-radius: 100px;
}
.navSubHead{
  max-width: calc(100% - 45px);
}
.coursename, .unitname{
  list-style-type: none;
  padding-left: 30px;
  text-wrap: wrap;
}
.coursename h5{
  color: #fff;
  opacity: .8;
  font-size: 20px;
  font-weight: 300;
  
}
.unitname h4{
  color: #fff;
  text-wrap:auto;
  
  font-size: 24px;
  font-weight: 900;
}
.sectionArea .MuiPaper-root.MuiPaper-rounded {
  color: #fff !important;
}
.sectionName{
  color: #fff;
  padding-left: 0px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.sidenavHeader{
  position: absolute;
  left: 25.2vw;
  border: 2px solid rgba(30, 41, 59, 0.61);
  border-radius: 50%;
  z-index: 1300;
  top: 130px;
  background-color: rgba(5, 13, 21, 1);
  color: #fff;
}
.sidenavHeader button, .closedNav button{
  color: #fff;
  padding: 9px;
}
.closedNav{
  position: absolute!important;
  border: 2px solid rgba(30, 41, 59, 0.61);
  border-radius: 50%;
  top: 130px;
  background-color: rgba(5, 13, 21, 1);
  color: #fff;
  left: 40px;
  z-index: 1300;
  height: auto;
  width: auto;
  min-height: 30px!important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0!important;
  padding-right: 0!important;
}
.closedNav button{
  margin-left: 0;
  margin-right: 0!important;
  padding: 9px;
  color: #fff;
}
main h2{
  color: #fff;
}
.scroll-container h6{
  color: #fff;
}
.lessonName{
  font-size: 15px;
  max-width: 18vw;
  white-space: pre-line;
}
.lessonName .MuiListItemText-root{
  margin-top: 0;
  margin-bottom: 0;
}
.lessonName span{
  font-size: 15px;
  margin-left: 15px;
  opacity: .8;
  color: #fff;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
}
.smallLesson{
  font-size: 14px;
  margin-left: 42px;
  max-width: 18vw;
  white-space: pre-line;
  font-weight: 300;
  position: relative;
}
.smallLesson::before{
position: absolute;
left: -33px;
content: "";
width: 7px;
height: 7px;
background-color: rgba(5, 13, 21, 1);
border-radius: 50%;
top: 11px;
border: 1px solid rgb(51, 65, 85);
}
.smallLesson div{
  padding-top: 2px;
  padding-bottom: 2px;
}
.smallLesson span{
  font-size: 14px;
  opacity: .6;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}
 ul .iconIndex{
 
  justify-content: center;
  align-items: center;
  min-width: 25px!important;
  width: 25px;
  height: 25px;
  border: 1px solid rgba(51, 65, 85, 1);
  border-radius: 50%;
 
}
ul .iconIndex span{
  font-family: Poppins, sans-serif!important;
  font-size: 12px;
  color: rgb(171, 182, 197);
}
.navHeading{
  display: flex;
  padding: 0 20px;
  margin: 0 0 20px;
}
.goBackbtn{
  align-self: flex-end;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 0px solid #fff;
  margin-bottom: 5px;
}
.goBackbtn2{
  align-self: flex-end;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 0px solid #fff;
  margin-left: 7%;
  position: absolute;
  top: 170px;
  z-index: 1300;
}
.gradientHR{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.025) 0%, rgba(114, 239, 221, 0.25) 50.03%, rgba(255, 255, 255, 0.025) 100.05%);
}
.mdjourney-container .wrongAnswer {
  border: 1px solid rgba(248, 113, 113, 1)!important; 
  transition: 0.0s all ease-in-out;
  box-shadow: 0 0 4px 1px rgba(248, 113, 113, .35);
}
.mdjourney-container .correctAnswer{
  border: 1px solid #fff!important;
  transition: 0.0s all ease-in-out;
  box-shadow: 0 0 4px 1px #ffffff60;
}
.mdjourney-container .rightAnswer {
  border: 1px solid rgba(33, 213, 142, 1)!important;/* Green border for correct answer */
  transition: 0.0s all ease-in-out;
  box-shadow: 0 0 4px 1px rgba(33, 213, 142, .35);
}
.mdjourney-container .wrongAnswer span, .mdjourney-container .wrongAnswer label{
  color: rgba(248, 113, 113, 1);
}
.mdjourney-container  .correctAnswer span, .mdjourney-container .correctAnswer label{
  color: #fff;
}
.mdjourney-container .rightAnswer span, .mdjourney-container .rightAnswer label{
  color: rgba(33, 213, 142, 1);
}
.wrongAnswer{
  background-image: url('../assets/image_cross.svg');
  background-repeat: no-repeat;
  background-position: 97% 50%;
}
.rightAnswer{
  background-image: url('../assets/image_check.svg');
  background-repeat: no-repeat;
  background-position: 97% 50%;
}
.sectionArea{
  position: relative;
}
.sectionArea::before{
  content: "";
  width: 1px;
  height: calc(100% - 24px);
  position: absolute;
  background-color: rgba(51, 65, 85, 1);
  left: 12px;
  top: 41px;
}
.sectionArea .listLesson, .sectionArea .listLesson li {
  padding-top: 1px;
  padding-bottom: 1px;
}
.subHeading{
  max-width: calc( 100% - 50px);
}
.subHeading h4, .subHeading p{
  text-wrap: wrap;
}
.topicDropdown.MuiPaper-root.MuiPaper-rounded{
  border: 0px solid rgba(255, 255, 255, 0.05);
  box-shadow: none;
}
.sectionDropdown.MuiPaper-root.MuiPaper-rounded{
  border: 0px solid rgba(255, 255, 255, 0.05);
  box-shadow: none;
}
.topicDropdown .MuiButtonBase-root.MuiAccordionSummary-root{
  padding: 0 0;
}
.sectionDropdown .MuiAccordionDetails-root {
  padding: 0px 0px 0px;
  flex-direction: column;
}
.topicDropdown .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0px;
}
.sectionDropdown .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0px;
}
.topicDropdown .MuiAccordionSummary-content{
  margin: 0px 0px;
}
.sectionDropdown  .MuiAccordionSummary-content{
  margin: 0px 0px;
}
.sectionDropdown .MuiIconButton-root svg{
  font-size: 14px;
  margin: 0 6px;
}
.topicDropdown .MuiAccordionDetails-root {
  padding: 0px 0px 0px;
  flex-direction: column;
}
.dropDownsection{
  padding: 0px 20px;
  margin-top: 20px;
  text-wrap: auto;
}
.dropDownsection .MuiListItem-gutters {
  padding-left: 0px;
  padding-right: 0px;
}
.dropDownsection .MuiAccordionSummary-root.Mui-expanded {
  min-height: 30px;
}

.iconArrow .MuiAccordionSummary-expandIcon{
  border: 1px solid rgba(255,255,255,.1);
  border-radius: 50%;
  margin: 0 auto;
  padding: 4px;
}
  
.iconArrow .MuiAccordionSummary-expandIcon svg{
  font-size: 15px;
}
.goBackbtn, .goBackbtn2{
  cursor: pointer;
}
.dropDownsectionClose{
  padding: 0px 17px;
  margin-top: 20px;
}
.dropDownsectionClose .MuiAccordionSummary-expandIcon, .dropDownsectionClose .smallLesson::before{
  display: none;
}
.sectionArea .listData{
  align-items: flex-start;
}
.mainheader.container-header{
  max-width: none;
}
.sectionArea.true ul .iconIndex{
  border: 1px solid rgba(114, 239, 221, 1);
  background-color: rgba(114, 239, 221, 0.2);
}
.sectionArea.true ul .iconIndex span{
 color: #fff;
}
.sectionArea.true::before {
  background-color: rgba(114, 239, 221, 1);
}
.sectionArea.true .smallLesson::before{
  border: 1px solid rgba(114, 239, 221, 1);
}
.iconIndex img{
  opacity: .4;
}
.iconIndex.true{
  border: 1px solid rgba(114, 239, 221, 1);
  background-color: rgba(114, 239, 221, 0.2);
}
.iconIndex.true img{
  opacity: 1;
} 
.MuiGrid-root.MuiGrid-item:has(.comingsoon){
  display: none;
}

.scoreDrawer .MuiDrawer-paper.MuiPaper-root::-webkit-scrollbar {
  width: 3px;
}
.scoreDrawer .MuiDrawer-paper.MuiPaper-root::-webkit-scrollbar-thumb {
  background-color: #1E293B;
}
.footersmall{
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.social-links-container p{
  color: rgba(255, 255, 255, 0.5)!important;
}
/* Media Queries */
@media screen and (min-width: 380px) {
  .headerMain {
    padding: 20px;
  }
  .displayMenu {
    width: 100%;
  }
}

@media screen and (max-width: 926px) {
  .firstBtn {
    margin-left: 0px;
    border-width: 2px;
    margin-bottom: 20px;
    margin-top: 12px;
  }
  .secondBtn {
    margin-left: 25px;
    margin-bottom: 10px;
  }
  .centerIcon {
    font-size: 12px !important;
    font-weight: 800 !important;
    text-align: center;
    width: 92%;
    margin-left: -11px !important;
  }
}

@media screen and  (min-width: 200px) and (max-width: 300px){
.commonBox {
    width: 60px !important;
    height: 60px !important;
    border-radius: 40px;
}
.knowledgeLineContainer {
  width: 270px !important;
  margin-bottom: 50px !important;
}
.smallBtn {
  width: 40px;
  height: 40px;
}
.thirdBtn {
  margin-left: 35px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.secondBtn {
  margin-left: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.leftIconSize{
  height: 38px;
  /* width: 20px; */
}
.rightIconSize{
  height: 36px;
  /* width: 20px; */
}
.leftIcon {
  font-size: 10px !important;
  text-align: center;
}
.rightIcon {
  font-size: 10px !important;
  text-align: center;
}
.centerIcon {
  font-size: 11px !important;
  font-weight: 700 !important;
  text-align: center;
  width: 92%;
}
.firstBtn {
  margin-left: 15px;
  border-width: 2px;
  margin-bottom: 21px;
  margin-top: 15px;
}
.centerIconSize{
  height: 20px;
  width: 20px;
}
}




@media screen and (min-width: 400px) {
  .headerMain {
    padding: 20px 30px;
  }
}

@media screen and (min-width: 500px) {
   .searchContainer{
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .heading {
    font-size: 2.145rem !important;
  }
}
@media screen and (min-width: 685px) {
  .headerMain {
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
  .searchContainer {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .headerMain {
    padding: 20px 40px;
  }
  .logoContainer {
    width: auto;
  }
}

@media screen and (max-width: 550px) {
  .simulateBtn{
    width:80%
  }
  .statsContainer{
    width: 85%;
    padding: 5%;
  }
  .CircleContainer {
    width:315px;
    height: 315px;
  }
  .commonBox {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
  .positionSecond {
    top: 87px;
    left: -22%;
   
  }
  .secondBtnTitle {
    width: 215px;
    height: 55px;
  }
  .secondBtnKnowledgeTitle {
    width: 194px;
  }
  .thirdBtnTitle {
    width: 97px;
  }

  .positionthird {
    top: 87px;
  }
  .positionFourth {
    top: 252px;
    left: 29px;
  }
  .positionFifth {
    top: 252px;
  }
  .newTask {
    width: 100%;
  }
  .addNewTask {
    width: 100%;
  }
  .knowledgeStatsContainer{
    margin: 30% 1% 5% !important;
    height: auto !important;
  }
  .graphContainer{
    margin: 30% auto 20% !important;
    height: auto !important;
    padding: 20px;
  }
  .content{
    flex-wrap: wrap;
  }
  .left{
    width: 100% !important;
  }
  .right{
    width: 100% !important;
  }
  .knowledgeLineContainer{
    width: 325px;
    margin-bottom: 50px !important;
  }
  .mainQueSimulateContainer{
    margin: 10px 0;
    flex-wrap: wrap;

  }
  .newTaskQu{
    width: 100%;
    margin-left: 25px;
    margin-bottom: 20px;
    height: auto;
  }
  .cardMainContainer{
    width: 85%;
  }
  .medMainContainer{
    width: 85%;
    padding: 5%;
  }
  .QueNewContainer{
    width: 85%;
  }
}
.hover-white-text:hover {
  color: white !important;
}

@media screen and (max-width: 684px) {
  .taskContainer {
    padding: 14% 40px 3% ;
  }
  .welcomeTxt {
    text-align: center;

  }
  .cardTitle {
    font-size: 16px !important;
    font-weight: 900;
    color: #FFFFFF;
  }
  .medBodySubText {
    font-size: 14px !important;
    text-align: center;
  }

}
@media screen and (max-width: 767px) {
  .logoContainer {
    width: 100% !important;
    text-align: center;
  }
  .searchContainer{
    width: 100%;
    justify-content: center;
    margin-top: 10px !important;
  }
  .displayMenuText {
    width: auto;
    padding: 0px 5px
  }

  .taskContainer {
    padding: 20% 50px 3% ;
  }

  .addNewTask{
    margin-top: 50px;
    width: 100% !important;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .newTask{
    margin-top: 50px;
    width: 100% !important;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .upcoming {
    width: 100% !important;
    margin-left: 0px;
    margin-bottom: 50px;
  }
  .questionImg {
    right: 10px;
  }
  .cardMainContainer {
    padding: 10px;
  }
  .cardMapContainer {
    width: 100% !important;
    margin-bottom: 20px;
  }

 .medContainer {
    width: 100%;
    margin: 1px; 
    align-items: center;
    
  }
}
@media screen and (max-width: 950px) {
  .cardMapContainer {
    width: 100% !important;
  }
  .displayMenu {
    width: 100%;
  }
  .UpperContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    flex-wrap: wrap !important; 
    justify-content: center;
  }
  .upperKnowledgeContainer{
    display: flex;
    gap:30px;
    width: 100%;
    margin: 0px auto;
    flex-wrap: wrap !important; 
    justify-content: center !important;
    align-items: center !important;
  }
  .graphContainer{
    margin: 18% auto 2%;
  }
  .knowledgeStatsContainer{
    margin: 20% auto 2%;
  }
  .upcoming {
    margin-top: 20px;
    width: 70%;

  }
  .addNewTask {
    margin-top: 50px;
    width: 70%;
    margin-bottom: 20px;
  }
  .newTask{
    margin-top: 50px;
    width: 70%;
    margin-bottom: 20px;
  }
  .mainQueSimulateContainer{
    margin: 10px 0;

  }
}

@media screen and (max-width:1100px) {
  .UpperContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
  .upperKnowledgeContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    flex-wrap: wrap !important; 
    justify-content: center !important;
  }
  .upcoming {
    margin-top: 50px;
  }
}
@media screen and (max-width:1200px) {
  .UpperContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    justify-content: center;
    flex-wrap: wrap !important;
  }
  .upperKnowledgeContainer{
    display: flex;
    width: 100%;
    margin: 0px auto;
    justify-content: center;
    flex-wrap: wrap !important;
    gap: 40px;
  }
  .upcoming {
    margin-top: 50px;
  }
  .cardMapContainer {
    width: 45% ;
    margin: 15px;
  }
  .CradBox {
    justify-content: center;
    flex-wrap: wrap;
  }
  .medContainer {
    width: 100%;
  }
}

@media screen and (max-width:1440px){
  .statsContainer, .UpperContainer, .upperKnowledgeContainer, .medMainContainer, .cardMainContainer, .QueNewContainer{
    width: 100%;
    max-width: 1154px;
  }
  .welcomeTxt, .welcomeBottom-border{
    width: 100%!important;
    max-width: 1154px;
  }
 
} 
@media screen and (max-width:1500px) {
  .upperKnowledgeContainer{
    justify-content: space-evenly;
  }
}
/* styles.css */
.button-with-gradient-border {
  /* Set button background color */
  background-color: transparent;

  /* Apply linear gradient as border */
  border: 2px solid;
  border-image: linear-gradient(to right, rgba(98, 0, 234, 1), rgba(38, 197, 243, 1)) 1;
}
.welcomeBottom-border {
  width: 75%;
  display: block;
  height: 1px;
  margin: 0 auto 80px;
  /* padding-bottom: 30px; */
  margin-top: 10px;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.5) 100.05%);

/* 
  linear-gradient(90deg, #26C5F3, #26C5F315);  */
} 

@media screen and (min-width: 1600px){
  .sidenavHeader{
    left: 25.5vw;
  }
}
@media screen and (min-width: 2000px){
  .sidenavHeader{
    left: 25.9vw;
  }
}
@media screen and (min-width: 2500px){
  .sidenavHeader{
    left: 26.2vw;
  }
}
@media screen and (min-width:1500px){
  .cardimagedata{
    background-size: 110% auto!important;
  }

}