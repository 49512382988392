.desc-text {
  font-weight: 600 !important;
  margin-top: -20px !important;
}

.formcontent-wrapper,
.mdjourney-container,
.roles-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px 5px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  font-family: "Raleway", sans-serif;
  z-index: 1;
  margin-top: 30px;
}

.suggestions-dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #0b0d33;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 15px;
}

.suggestion-item.highlighted {
  /* border: 2px solid;
  border-image: linear-gradient(90deg, #5591F5 0%, #6200EA 25%, #5591F5 50%);
  border-image-slice: 1; */
  background-color: #282e61; 
}

.suggestion-item {
  padding: 12px;
  cursor: pointer;
  color: #FFFFFF;
  border-width: 100%;
}
.item-text{
  margin-left: 30px;
}
.suggestion-item-line-bottom{
  border-bottom: 1px solid black;
}





.input {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 13px 20px 10px 28px;
  border: none;
  outline: none;
  border-radius: 20px;
  background-color: #111921;
  transition: all 0.3s ease-in-out;
}

.inputWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.suggestion-item:hover {height: "100%";

  /* border: 2px solid;
  border-image: linear-gradient(90deg, #5591F5 0%, #6200EA 25%, #5591F5 50%);
  border-image-slice: 1; */
  background-color: #282e61; 
}


.vertical-container {
  width: 100%;
  max-width: 280px;
  background-color: #ffffff05;
  border-radius: 20px;
  padding: 1px;
  margin-bottom: 10px;
  transition: 0.3s all ease-in-out;
  position: relative;
}

.selected-container {
  background-color: #ffffff05;
  background-image: linear-gradient(to bottom, #ffffff60, #ffffff00);
  transition: 0.3s all ease-in-out;
}

.vertical-wrapper {
  max-width: 279px;
  background-color: #050d1560;
  border-radius: 20px;
  display: flex;
  align-items: center;
  transition: 0.3s all ease-in-out;
  position: relative;
}

.selected-wrapper {
  background-color: #050d1500;
  background-image: linear-gradient(to bottom, #050d1500, #050d1590);
  transition: 0.3s all ease-in-out;
}

.vertical-label,
.mdJourney-label,
.roles-label {
  font-weight: 700;
  font-size: 1rem;
  color: rgba(255,255,255,.7);
  transition: 0.3s all ease-in-out;
  width: 100%;
  padding: 14px 10px 14px 70px;
  z-index: 2;
  word-wrap: break-word;
  padding-right: 45px;
}

.goal-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 10px 14px 0;
  z-index: 2;
}

.vertical-icon,
.selected-icon,
.mdJourney-icon,
.roles-icon {
  transition: 0.3s all ease-in-out;
  position: absolute;
  left: 30px;
  z-index: 1;
}

.selected-label,
.mdJourney-selected-label,
.roles-selected-label,
.dropdown:focus {
  color: #ffffff;
  transition: 0.3s all ease-in-out;
}

.vertical-icon img,
.mdJourney-icon img,
.roles-icon img {
  width: 22px;
  height: 20px;
}

.selected-icon img,
.mdJourney-selected-icon img,
.roles-selected-icon img,
.dropdown:focus img,
.selected-goal-icon img {
  filter: grayscale(100%) brightness(1000%);
  transition: 0.3s all ease-in-out;
}

.radioBtn {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
  z-index: 2;
}

.mdjourney-container,
.roles-container {
  flex-direction: column;
}

.mdjourney-container span,
.roles-container span {
  position: absolute;
  left: 24px;
}

.mdJourney-wrapper,
.roles-wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 413px;
  background-color: #050d1550;
  border: 1px solid #ffffff00;
  border-radius: 24px;
  margin: 10px;
  transition: all 0.3s ease;
  position: relative;
}

.mdJourney-selected-wrapper,
.roles-selected-wrapper {
  border: 1px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff60;
  background-color: transparent;
  transition: all 0.3s ease;
}

.institution-container,
.goal-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.institution-wrapper,
.textarea-wrapper {
  width: 100%;
  max-width: 413px;
  position: relative;
  resize: none;
}

.dropdown,
.textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../../assets/skipArrow.svg");
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 20px;
  width: 100%;
  background-color: #050d1570;
  border: 0.5px solid #ffffff00;
  border-radius: 24px;
  padding: 14px 30px 14px 50px;
  outline: none;
  color: #ffffff80;
  font-weight: 700;
  font-size: 1rem;
  transition: all 0.3s ease;
}


.textInput::placeholder {
  color: #FFFFFF;
  font-size: 16px;

  
}

.institutionIcon {
  display: block;
  width: 23px;
  height: 20px;
  position: absolute;
  top: 27px;
  left: 20px;
  transition: all 0.3s ease;
}

.institution-icon {
  width: 100%;
  height: auto;
  margin-top: 5px;
}

.dropdown:focus,
.textarea:focus {
  outline: none;
  border: 0.5px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff50;
  background-color: transparent;
  color: #ffffff;
}

.option {
  background-color: #ffffff;
  color: #050d15;
}

.textInput,
.textarea {
  margin-top: 20px;
  background-image: none;
  /* padding: 14px 24px; */
}

.contentContainerStyle {
  padding: 150px 0;
}

.goal-container {
  flex-direction: row;
  flex-wrap: wrap;
}
.goal-container  .MuiAccordion-root{
  width: 100%;
  flex-basis: 100%;
  max-width: 600px;
}

.goal-wrapper {
  width: 100%;
  background-color: #050d1570;
  border: 1px solid #ffffff00;
  border-radius: 24px;
  margin: 15px 0;
  color: #ffffff80;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}

.selected-goal-wrapper {
  background: transparent;
  border: 0.5px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff50;
  color: #ffffff;
  font-weight: 700;
  font-size: 1rem;
}

.goal-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  transform: translateX(20px);
}

.goal-icon {
  margin: 0 20px;
  display: block;
  z-index: 1;
}

.textarea {
  position: relative;
  padding: 20px 20px;
  display: flex;
  resize: none;
}

.textarea::-webkit-scrollbar {
  display: none;
}

.text-counter {
  color: #ffffff70;
  font-weight: 600;
  font-size: 1.1rem;
  text-align: right;
  margin: 10px 30px;
}

.text-counter p {
  margin: 0;
  padding: 0;
}

.msg-container {
  display: flex;
  align-items: center;
}

.msg {
  background-clip: text;
  background-image: linear-gradient(to right, #72efdd, #26c5f3, #5591f5, #6a00f4, #b429f9);
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 900 !important;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 3px;
  padding: 100px 15px 0;
}

.gradientText {
  background-clip: text;
  background-image: linear-gradient(to right, #72efdd, #26c5f3, #5591f5, #6a00f4, #b429f9);
  color: #9b9ea1;
  font-size: 2.5rem;
  font-weight: 100 !important;
  -webkit-text-stroke-width: 3px;
}

.gradientText2 {
  background-image: linear-gradient(to right, #72efdd, #26c5f3, #5591f5);
}

.msg2 {
  background-image: linear-gradient(to right, #72efdd, #26c5f3, #5591f5);
  padding: 0;
}

.h3Style {
  background-clip: text;
  background-image: linear-gradient(to right, #72efdd, #26c5f3 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.progress-wrapper {
  align-self: center;
  width: 100%;
  height: 10px;
  max-width: 1000px;
  background-color: #a4a4b0;
  border-radius: 5px;
  position: relative;
  box-shadow: 5px 2px 14px #ffffff50;
}

.progress {
  position: absolute;
  top: -48px;
  left: 0;
  right: 0;
  transform: translate(-10px, -50%);
  background-image: url(../../assets/progressBg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 52px;
  height: 30px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: #6a00f4;
  margin: 50px 0 30px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar {
  height: 100%;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.5) 100%);
}

.progress-bar::-moz-progress-bar {
  background: linear-gradient(to right, #72efdd, #26c5f3, #5591f5, #6a00f4, #b429f9);
}

.progress-bar::-webkit-progress-bar {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.5) 100%);
}

.progress-bar::-webkit-progress-value {
  background: linear-gradient(to right, #72efdd, #26c5f3, #5591f5, #6a00f4, #b429f9);
  border-radius: 10px;
}

.fact {
  align-self: center;
  background-image: url(../../assets/bgBlur.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  max-width: 700px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  margin-top: 80px;
}

.fact p {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}

.bgImgStyle {
  position: absolute;
  width: 100%;
  left: 0;
  top: 60px;
  height: 40vh;
}

/* customReactSelect.css */
.custom-select__control {
  border-radius: 35px !important;
  padding: 7px !important;
  background-color: #0515147A !important;
  margin: 1px !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.custom-select__control:hover {
  border-color: transparent !important;
}

.custom-select__value-container {
  padding: 0 !important;
}

.custom-select__placeholder {
  color: white !important;
  margin-left: 60px !important;
}

.custom-select__input {
  color: white !important;
  margin-left: 50px !important;
}

.custom-select__single-value {
  margin-left: 50px !important;
  color: white !important;
}

.custom-select__menu {
  padding: 7px !important;
  background-color: #0515147A !important;
  margin: 1px !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.custom-select__option {
  background-color: #0515147A !important;
  color: white !important;
  padding: 7px !important;
  margin: 1px !important;
}

.custom-select__option--is-selected {
  background-color: #051514 !important;
}
.goal-container .MuiAccordion-root.Mui-expanded {
  margin: 0px 0px;
}
.goal-container .MuiAccordionSummary-root.Mui-expanded{
  min-height: 40px;
}
.goal-container .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0;
}
.goal-container  .MuiAccordionDetails-root{
  padding: 8px 16px 8px;
}
.goal-icon img{
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.goal-icon{
  align-self: flex-start;
}
.back-btn{
  left: 0;
  background-color: rgba(255, 255, 255, 0.11);
}
.content-wrapper{
  padding-left: 0;
  padding-right: 0;
}
/* Media Queries */
@media screen and (min-width: 600px) {

  .formcontent-wrapper,
  .mdjourney-container,
  .roles-container {
    justify-content: space-between;
  }

  .desc-text {
    font-size: 1.5rem !important;
  }

  .bgImgStyle {
    top: 50px;
    height: 60vh;
  }

  .msg,
  .gradientText {
    font-size: 3rem;
  }
}

@media screen and (min-width: 650px) {
  .goal-wrapper {
    max-width: 600px;
  }
}

@media screen and (min-width: 1024px) {
  .bgImgStyle {
    width: auto;
    height: auto;
    top: 10px;
    left: -150px;
  }

  .msg {
    font-size: 4.2rem;
    padding: 0 15px;
  }

  .gradientText {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1600px) {
  .bgImgStyle {
    top: 10px;
    left: -90px;
    width: auto;
  }
}