.school-section {
    margin: 0;
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
    position: relative;
}

.section-wrapper,
.school-heading,
.partners-wrapper,
.partner-group,
.partner-info,
.partner-group-wrapper,
.testimonial-container,
.testimonials-container,
.testimonials-wrapper,
.testimonial-wrapper,
.testimonial,
.pricing-content-container,
.category-card-container,
.card-top,
.card-bottom,
.cat-features-wrapper,
.pricing-container,
.landing-page-footer,
.footer-top,
.footer-links-container,
.footer-links-wrapper,
.motto,
.social-links-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.partners-info,
.partner-group-wrapper,
.partner-btn-wrapper,
.school-heading,
.testimonials-container {
    padding: 0 10px;
}
.partners-info .gradient-text{
    font-size: inherit;
}
.section-container {
    background-image: url("../../assets/schoolBg.svg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-basis: 100%;
}

.school-btn {
    position: relative;
    right: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.section-wrapper {
    z-index: 2;
    padding: 80px 0 50px;
}

.school-heading h2 {
    text-align: center;
}

.school-heading h2:nth-child(1) {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2.5rem;
    letter-spacing: 5px;
}

.school-heading h2:nth-child(2) {
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
    text-shadow: 0 0 3px #fff;
    margin: 10px 0 40px;
}

.partners-info {
    font-size: 1rem;
    line-height: 35px;
}

.partner-gradient-text {
    font-size: 1rem;
    font-weight: 600;
    -webkit-text-fill-color: #ffffff60;
    background-image: linear-gradient(90deg, #72efdd, #26c5f3, #b429f950);
}

.partners-wrapper {
    justify-content: space-between;
    margin: 30px 0;
    position: relative;
}

.partner-group {
    flex-direction: row;
    justify-content: space-between;
}

.pulse-bg {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.partner-group-wrapper {
    padding: 0 10px;
}

.partner {
    width: 100px;
    height: 50px;
}

.partner-info {
    margin: 25px 0;
}

.number {
    font-family: "Poppins", sans-serif !important;
    font-size: 3.5rem;
    font-weight: 500;
    text-shadow: 0 0 5px #fff;
}

.partner-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.student-container {
    background-image: url("../../assets/studentBg.svg");
}

.student-wrapper {
    background-image: linear-gradient(180deg, #2583cb34, #2341a367);
}

.student-gradient-text {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #72efdd, #26c5f3 40%);
}

.curvesection {
    position: relative;
    background-image: url("../../assets/gradientbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: ellipse(120% 50% at 50% 50%);
    background-position: 50% 50%;
    display: flex;
    flex-wrap: wrap;
}

.curvesection #school {
    order: 1;
    padding-top: 80px;
}

.curvesection #student {
    order: 2;
}

.curvesection #government_agency {
    order: 3;
    padding-bottom: 30px;
}

.govt-container {
    background-image: url("../../assets/governmentBg.svg");
}

.govt-wrapper {
    background-image: linear-gradient(180deg, #2341a367, #22007c50);
}

.govt-gradient-text {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #72efdd, #26c5f3 40%);
}

.testimonial-section {
    margin: 130px 0;
}

.testimonials-container {
    z-index: 2;
}

.testimonial-info h2,
.testimonial-info p {
    text-align: center;
}

.testimonial-info h2 {
    font-weight: 900;
    font-size: 2rem;
    line-height: 50px;
}

.testimonial-info p {
    margin: 20px 0 50px;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 25px;
}

.testimonial-wrapper {
    max-width: 600px;
    flex-direction: column-reverse;
    justify-content: space-between;
    position: relative;
    padding: 30px;
    margin: 20px 0;
    border-radius: 20px;
    background-color: #21272d95;
    backdrop-filter: blur(10px);
}

.testimonial-wrapper::before {
    backdrop-filter: blur(10px);
}

.testimonial {
    align-items: flex-start;
}

.quote {
    width: 20px;
    height: 20px;
    margin: 20px 0 5px;
}

.testimonial-text {
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: 300;
    line-height: 20px;
}

.testifier-name,
.testifier-title {
    font-size: 1rem;
}

.testifier-name {
    font-weight: 900;
}

.testifier-title {
    font-weight: 200;
}

.pricing-content-container {
    max-width: 1400px;
    align-self: center;
    padding-top: 80px;
}

.pricing-section {
    max-width: 100%;
    position: relative;
    background-image: linear-gradient(180deg, #22007c08, #0f172a50, #22007c08);
}
.highlight-text{
    background-image: linear-gradient(130deg, #72efdd, #26c5f3, #b429f9 90%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.pricing-content {
    z-index: 2;
    max-width: 100%;
}

.pricing-heading,
.pricing-heading2 {
    font-size: 1.3rem;
    text-align: center;
}

.pricing-heading {
    font-weight: 400;
}

.pricing-heading2 {
    font-weight: 700;
    margin-top: 20px;
}

.pricing-category-select {
    width: 100%;
    max-width: 400px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    background-color: #050d15;
    border: 1px solid #32276b;
    border-radius: 30px;
    padding: 0 10px;
    margin: 30px 0;
}

.pricing-btn-wrapper,
.pricing-btn-wrapper2 {
    width: auto;
    height: 30px;
    border-radius: 20px;
    background-image: linear-gradient(to right, #6200ea, #26c5f3);
    padding: 1px;
    display: inline-block;
    margin: 5px 0;
    z-index: 2;
    transition: all 0.3s ease-in-out;
}

.pricing-btn-wrapper2 {
    background-image: linear-gradient(to right, #6200ea00, #26c5f300);
}

.pricing-btn {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #050d15;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 0 10px;
    border: 0;
    transition: all 0.5s ease-in-out;
}
.cat-Advanced .pricecatAdvanced .card-top, .categoryprice:nth-child(2) .pricecatAdvanced .card-top{
    background-color: #590085;
}
.cat-Advanced .pricecatAdvanced .card-bottom, .categoryprice:nth-child(2) .pricecatAdvanced .card-bottom{
    background-color: rgb(67, 17, 91);
}

.categoryprice.order1 .pricecatAdvanced .card-top, .categoryprice.order3 .pricecatAdvanced .card-top,
.categoryprice.order1 .pricecatAdvanced .card-bottom, .categoryprice.order3 .pricecatAdvanced .card-bottom{
    background-color: rgba(0, 0, 0, 0.3);
}
.cat-Advanced .pricecatAdvanced .card-icon-wrapper, .categoryprice:nth-child(2) .pricecatAdvanced .card-icon-wrapper {
    width: 80px;
    height: 80px;
    margin-top: -70px;
}
.categoryprice.order1 .pricecatAdvanced .card-icon-wrapper, .categoryprice.order3 .pricecatAdvanced .card-icon-wrapper {
    width: 70px;
    height: 70px;
    margin-top: -75px;
}
.cat-Essential{
    order: 1;
    max-width: 322px;
    flex-basis: 30%;
}
.cat-Advanced{
    order: 2;
    margin-top: -140px;
    max-width: 328px;
    flex-basis: 31%;
}
.cat-Professional{
    order: 3;
    max-width: 318px;
    flex-basis: 30%;
}
.categoryprice.order2 .pricecatEssential .card-top {
    background-color: rgb(58, 184, 135);
}

.categoryprice.order2 .pricecatAdvanced .card-top {
    background-color: #590085;
}

.categoryprice.order2 .pricecatProfessional .card-top {
    background-color: #170080;
}

.categoryprice.order2 .pricecatEssential .card-bottom {
    background-color: rgb(50, 113, 88);
}

.categoryprice.order2 .pricecatAdvanced .card-bottom {
    background-color: rgb(67, 17, 91);
}

.categoryprice.order2 .pricecatProfessional .card-bottom {
    background-color: #120062;
    padding: 30px 40px;
}

.categoryprice.order2 .card-icon-wrapper {
    width: 80px;
    height: 80px;
    margin-top: -70px;
}

.category-card-container {
    margin-top: 120px;
    max-width: 100%;
}

.card-wrapper,
.card-wrapper-active {
    width: 100%;
    height: 580px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 50px;
    transition: all 0s ease-in-out;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.card-wrapper-active {
    border: 1px solid #43115b00;
}

.card-wrapper::before,
.card-wrapper-active::before {
    backdrop-filter: blur(10px);
    border: 1px solid #43115b00;
}

.card-wrapper-active-school,
.card-wrapper-active-student,
.card-wrapper-active-govt {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pricecatEssential,
.pricecatAdvanced,
.pricecatProfessional {
    border-radius: 20px;
    width: 100%;
}

.pricecatEssential, .categoryprice.order1 .pricecatEssential, .categoryprice.order3 .pricecatEssential {
    border: 1px solid rgba(114,239, 221,.2);
    background-color: rgba(0, 0, 0, 0.3);
}
.pricecatAdvanced, .pricecatAdvanced, .categoryprice.order1 .pricecatAdvanced, .categoryprice.order3 .pricecatAdvanced {
    border: 1px solid rgba(161,38, 222, .2);
    background-color: rgba(0, 0, 0, 0.3);
}
.pricecatProfessional, .categoryprice.order1 .pricecatProfessional, .categoryprice.order3 .pricecatProfessional {
    border: 1px solid rgba(45, 0, 247, .2);
    background-color: rgba(0, 0, 0, 0.3);
}
.pricecatEssential .cat-name {
    border: 2px solid #72efdd;
    color: #72efdd;
}

.pricecatAdvanced .cat-name {
    border: 2px solid #a126de;
    color: #a126de;
}

.pricecatProfessional .cat-name {
    border: 2px solid #2d00f7;
    color: #2d00f7;
}

.card-top {
    padding: 30px 0;
    border-radius: 20px 20px 0 0;
}

.card-icon-wrapper,
.card-icon-wrapper-active {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -70px;
    transition: all 0s ease-in-out;
}

.pricecatEssential .card-icon {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/lock.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.pricecatAdvanced .card-icon {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/star.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.pricecatProfessional .card-icon {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/diamond.svg);
    background-repeat: no-repeat;
    background-size: contain;
}
.cat-Advanced .card-icon, .order2 .card-icon{
    width: 40px;
    height: 40px;
}
.order1 .card-icon, .order3 .card-icon{
    width: 30px;
    height: 30px;
}

.pricecatEssential .card-icon-wrapper {
    background-color: #72efdd;
    box-shadow: 0 10px 23px 3px #72efdd5c;
}
.cat-Advanced .horizontal-line{
display: none;
}
.pricecatEssential .horizontal-line {
    background-color: rgba(114, 239, 221, 0.25);
}

.order1 .pricecatAdvanced .horizontal-line, .order3 .pricecatAdvanced .horizontal-line  {
    background-color: rgba(45, 0, 247, 0.25);
    display: block;
}

.pricecatProfessional .horizontal-line {
    background-color: rgba(45, 0, 247, 0.25);
}

 .categoryprice.order2 .horizontal-line {
    display: none;
}

.pricecatAdvanced .card-icon-wrapper {
    background-color: #a126de;
    box-shadow: 0 10px 23px 3px #a126de7a;
}

.pricecatProfessional .card-icon-wrapper {
    background-color: rgb(45, 0, 247);
    box-shadow: 0 10px 23px 3px #2d00f79c;
}

.pricecatEssential .cat-feature-icon {
    color: rgb(114, 239, 221);
}

.pricecatAdvanced .cat-feature-icon {
    color: rgb(180, 41, 249);
}

.pricecatProfessional .cat-feature-icon {
    color: rgb(45, 0, 247);
}

.categoryprice.order1 {
    order: 1;
    margin-top: 0px;
    max-width: 322px;
    flex-basis: 30%;
}

.categoryprice.order2 {
    order: 2;
    margin-top: -140px;
    max-width: 328px;
    flex-basis: 31%;
   
}

.categoryprice.order3 {
    order: 3;
    margin-top: 0px;
    max-width: 318px;
    flex-basis: 30%;
}

.cat-Essential .cat-features, .cat-Professional .cat-features, .order1 .cat-features, .order3 .cat-features{
    font-size: 14px;
    opacity: .7;
}
.order2 .cat-features{
    font-size: 16px;
    opacity: 1;
}
.ideal {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin: 20px 20px 0;
}
.cat-Essential .ideal, .cat-Professional .ideal, .order1 .ideal, .order3 .ideal{
   opacity: .6;
   margin: 15px 20px 0;
   font-size: 14px;
}
.order2 .ideal{
    opacity: 1;
    margin: 20px 20px 0px;
    font-size: 16px;
}
.cat-Essential .cat-price, .cat-Professional .cat-price, .order1 .cat-price, .order3 .cat-price{
    opacity: .8;
    font-size: 1.4rem;
 }
 .order2 .cat-price{
    opacity: 1;
    font-size: 2rem;
 }
 .cat-Essential .cat-name, .cat-Professional .cat-name, .order1 .cat-name, .order3 .cat-name,
 .order1.cat-Advanced .cat-name ,  .order3.cat-Advanced .cat-name{
   font-size: .7rem;
 }
 .order2 .cat-name, .cat-Advanced .cat-name{
    font-size: .9rem;
 }

 .order2 .card-bottom, .cat-Advanced .card-bottom{
    padding: 30px 30px;
 }
 .order1.cat-Advanced .card-bottom, .order3.cat-Advanced .card-bottom{
    padding: 20px 20px;
 }
.cat-name {
    padding: 3px 10px;
    border-radius: 30px;
    text-transform: uppercase;
    font-size: 0.9rem;
    margin: 20px 0;
}

.price {
    display: flex;
    align-items: flex-start;
}

.cat-price {
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    font-size: 2rem;
    margin: 0 30px;
    text-align: center;
}

.price p:nth-child(2) {
    font-family: "Poppins", sans-serif !important;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-top: 9px;
    letter-spacing: 1px;
}



.horizontal-line {
    width: 80%;
    height: 1px;
    margin: 0 10%;
    display: inline-block;
}

.card-bottom {
    padding: 20px 20px;
    border-radius: 0 0 20px 20px;
    position: relative;
}

.card-bottom-active::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #00000030;
}

.cat-features-wrapper {
    flex-direction: row;
    justify-content: flex-start;
    margin: 7px 0;
}
.cat-Advanced .cat-features-wrapper, .order2 .cat-features-wrapper {
    margin: 10px 0;
}
.order1.cat-Advanced .cat-features-wrapper, .order3.cat-Advanced .cat-features-wrapper{
    margin: 7px 0;
}
.cat-feature-icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.cat-features {
    width: 80%;
    font-size: 16px;
}

.landing-page-footer {
    background-color: #050d15;
    border-radius: 50px 50px 0 0;
    border-top: 1px solid white;
    padding: 30px 0;
    height: auto;
    position: static;
}

.footer-top {
    padding: 0 30px 0;
    justify-content: space-between;
}

.footer-links-container {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    max-width: 700px;
}

.footer-links-wrapper {
    margin: 20px 0;
    max-width: 230px;
}

.links-heading {
    width: 100%;
    font-size: 1.2rem;
    color: #5591f5;
    margin-bottom: 10px;
    text-align: center;
}

.footer-link,
.footer-link a {
    width: 100%;
    align-self: center;
    color: #ccdefc;
    font-size: 1rem;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}

.footer-link {
    list-style-type: none;
}

.footer-link a {
    text-decoration: none;
}

.motto {
    max-width: 350px;
}

.footer-logo {
    margin: 0;
    width: 240px;
    margin-bottom: 30px;
}

.motto p {
    line-height: 30px;
    font-size: 1.1rem;
    text-align: center;
}

.footer-divider {
    display: block;
    margin: 50px 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, #ffffff05, #72efdd50, #ffffff05);
}

.social-links-container {
    justify-content: space-between;
    padding: 0 20px;
}

.social-links {
    width: 100%;
    max-width: 300px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footnote {
    text-align: center;
    margin-bottom: 30px;
}
.pricing-bg {
    width: 70%;
    max-width: 70%;
}
.cat-Essential.order2 .pricebgtype{
    position: absolute;
    width: 1000px;
    height: 1000px;
    background-image: url("../../assets/pricingBg.svg");
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-top: -220px;
    left: -250px;
}

.cat-Advanced .pricebgtype, .cat-Advanced.order2 .pricebgtype{
    position: absolute;
    width: 1000px;
    height: 1000px;
    background-image: url("../../assets/pricingBg2.svg");
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-top: -220px;
    left: -250px;
}

.cat-Professional.order2 .pricebgtype{
    position: absolute;
    width: 1000px;
    height: 1000px;
    background-image: url("../../assets/pricingBg3.svg");
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-top: -220px;
    left: -250px;
}
.cat-Advanced.order1 .pricebgtype,  .cat-Advanced.order3 .pricebgtype{
    background-image: none;
}
/* Media Queries */
@media screen and (min-width: 375px) {

    .partners-info,
    .partner-group-wrapper,
    .partner-btn-wrapper,
    .school-heading,
    .testimonials-container {
        padding: 0 20px;
    }

    .school-btn {
        right: 20px;
    }
}

@media screen and (min-width: 450px) {

    .partners-info,
    .partner-group-wrapper,
    .partner-btn-wrapper,
    .school-heading,
    .testimonials-container {
        padding: 0 30px;
    }

    .school-btn {
        right: 30px;
    }
}

@media screen and (min-width: 600px) {
    .testimonial-wrapper {
        flex-direction: row;
        padding: 30px;
    }

    .quote {
        margin: 0 0 5px;
    }

    .testimonial-text {
        max-width: 90%;
    }

    .social-links-container {
        flex-direction: row;
        align-items: center;
    }

    .social-links {
        margin-top: 0;
    }

    .footnote {
        margin: 0;
    }
}

@media screen and (min-width: 768px) {
    .partners-info {
        max-width: 900px;
    }

    .partners-wrapper,
    .partner-group-wrapper {
        flex-direction: row;
    }

    .partners-wrapper {
        justify-content: center;
    }

    .partner-group-wrapper {
        max-width: 1100px;
        align-self: center;
    }
}

@media screen and (min-width: 1024px) {
    .school-btn {
        right: 40px;
    }

    .school-heading h2:nth-child(1) {
        font-size: 4rem;
    }

    .school-heading h2:nth-child(2) {
        font-size: 1.4rem;
        margin: 20px 0 50px;
    }

    .partners-info,
    .partner-group-wrapper,
    .partner-btn-wrapper,
    .school-heading,
    .testimonials-container {
        padding: 0 40px;
    }

    .partners-info {
        font-size: 1.3rem;
        line-height: 45px;
        text-align: justify;
    }

    .partner-group {
        max-width: 30%;
    }

    .testimonials-container {
        max-width: 1500px;
        align-self: center;
    }

    .testimonials-wrapper {
        flex-direction: row;
        justify-content: space-between;
    }

    .testimonial-info {
        max-width: 500px;
    }

    .testimonial-info h2,
    .testimonial-info p {
        text-align: left;
    }

    .testimonial-info h2 {
        font-size: 2.6rem;
        line-height: 70px;
    }

    .testimonial-info p {
        font-size: 1.3rem;
        line-height: 30px;
    }

    .testimonial-container {
        align-items: flex-end;
    }

    .quote {
        width: 28px;
        height: 28px;
    }

    .category-card-container {
        max-width: 1100px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 120px;
    }

    .card-wrapper {
        display: flex;
        width: 30%;
    }

    .pricing-btn {
        font-size: 1rem;
    }

    .pricing-heading,
    .pricing-heading2 {
        font-size: 1.6rem;
    }

    .pricing-heading2 {
        margin: 10px 30px;
    }

    .pricing-bg {
        width: 70%;
        max-width: 50%;
    }

    .footer-top {
        max-width: 80%;
    }

    .social-links-container {
        max-width: 80%;
    }
}

@media screen and (min-width: 1100px) {
    .partner-group {
        max-width: 26%;
    }

    .testimonial-bg {
        margin-top: 150px;
    }

    .testimonial-info h2 {
        font-size: 3rem;
    }

    .testimonial-info p {
        font-size: 1.4rem;
    }
    .cat-price{
        margin: 0 20px;
    }
    .ideal{
        margin: 20px 20px 0;
    }

}

@media screen and (min-width: 1120px) {

    /* .landing-page-footer{
        padding: 100px 0;
    } */
    .footer-top {
        flex-direction: row;
        max-width: 80%;
        align-self: center;
    }

    .footer-links-wrapper {
        align-items: flex-start;
    }

    .links-heading,
    .footer-link,
    .footer-link a {
        text-align: left;
    }

    .footer-logo {
        align-self: flex-end;
    }

    .motto p {
        text-align: right;
    }
}

@media screen and (min-width: 1520px) {
    .school-btn {
        right: 150px;
    }
}

@media screen and (min-width: 1600px) {
    .school-btn {
        right: 250px;
    }
}