.container {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column;
  width: 100% !important;
  min-height: 100vh !important;
  padding: 0 !important;
  position: relative !important;
  z-index: 999 !important;
  font-family: "Raleway", sans-serif !important;
  color: #ffffff !important;
  overflow-x: hidden !important;
}

.container p,
.container h1,
.container h2,
.container span,
.container button {
  font-family: "Raleway", sans-serif !important;
}

.navContainer {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.navContainer::before {
  position: absolute;
  content: "";
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #272e3450;
  backdrop-filter: blur(10px);
}

.navWrapper {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.logo {
  width: 250px;
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.facts,
.bgImg {
  background-color: #050d15;
  position: relative;
  min-height: 40vh;
}

.bgImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.factsBgImg {
  position: absolute;
  z-index: 1;
}

.factsWrapper {
  z-index: 2;
  padding: 150px 15px 40px;
}

.heading {
  font-weight: 900 !important;
  font-family: "Raleway", sans-serif !important;
  line-height: 45px !important;
}

.factsHeading {
  font-size: 1.3rem !important;
}

.infoText {
  font-size: 1rem !important;
  text-align: left;
  font-weight: 400 !important;
}

.factsInfoText {
  margin-top: 20px;
  font-weight: 400 !important;
}

.factsInfoText2 {
  margin-top: 25px;
  font-weight: 600 !important;
}

.content {
  flex-direction: column;
  background-color: #1e252c;
  position: relative;
  padding: 120px 0 120px;
  z-index: 2;
  min-height: 60vh;
}

.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 500px;
  color: #ffffff;
}

.backBtn {
  padding: 5px;
  border-radius: 50%;
  background-color: #373c49;
  border: none;
  position: absolute;
  top: 9px;
  left: 0;
}

.bacBtnIcon {
  font-size: 1rem;
}

.formContent {
  width: 100%;
  max-width: 415px;
  position: relative;
  padding: 0 15px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
}

.headingWrapper {
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}

.heading1 {
  font-size: 1.3rem !important;
  margin: 0 !important;
}

.heading2 {
  font-weight: 300 !important;
}

.heading2,
.heading3 {
  font-size: 2rem !important;
  margin: 0 !important;
}

.colouredText {
  background: -webkit-linear-gradient(180deg, #b429f9, #26c5f3, #72efdd);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300 !important;
}

.errorWrapper {
  display: flex;
  align-items: center;
}

.errorText {
  font-size: 0.95rem !important;
  font-weight: 300 !important;
  color: #f87171 !important;
}

.popupContainer {
  position: absolute;
  top: 55px;
  align-self: flex-end;
  z-index: 2;
  left: 5%;
  right: 5%;
  width: 90%;
  display: flex;
  justify-content: center;
}

.popupError {
  width: 100%;
  max-width: 415px;
  display: flex;
  align-items: center;
  background-color: #f3ddb8;
  border-radius: 20px;
  padding: 8px 16px;
  border-left-width: 4px;
  border-left-style: solid;
}

.popupErrorText {
  transform: translateX(10px);
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  line-height: 16px !important;
  width: 80% !important;
}

.button {
  width: 100%;
  font-weight: 800;
  padding: 12px 30px;
  color: #ffffff;
  font-size: 16px;
  border: 0;
  border-radius: 20px;
  margin-top: 20px;
  background: linear-gradient(to right, #6201ea, #26c5f3);
}

.imgWrapper {
  width: 50%;
  background-color: #f5f6fb;
  border-bottom-left-radius: 60px;
  transform: translateX(60px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.img {
  width: 55%;
  position: absolute;
  right: -50px;
  top: 0;
}
.footer {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  background-color: #22007c;
  z-index: 10;
}

.validateEmailMsgContainer {
  width: 100%;
  max-width: 446px;
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  background-color: #cdcfd0;
  color: black !important;
  margin: 170px 10px 90px;
}

.logoWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffffff;
}

.logoWrapper img {
  width: 30px;
}

.emailValidatedMsgContainer {
  background-color: #ffffff00;
  justify-content: center;
  margin: 0;
  padding: 120px 0 40px;
}

.welcomeImg {
  width: 50%;
  max-width: 400px;
}
.validateEmailMsgWrapper {
  width: calc(100% - 50px);
}

.validateEmailMsgheading {
  font-weight: 600 !important;
  font-size: 1rem !important;
  margin-bottom: 10px !important;
}

.validateEmailText {
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  line-height: 20px !important;
}

/* Media Queries */
@media screen and (min-width: 400px) {
  .navWrapper {
    padding: 30px;
  }
  .factsWrapper {
    padding: 120px 30px 40px;
  }
  .formContent {
    padding: 0 30px;
  }
  .validateEmailMsgContainer {
    margin: 170px 30px 40px;
  }
  .emailValidatedMsgContainer {
    margin: 0;
  }
}

@media screen and (min-width: 600px) {
  .backBtn {
    left: -80px;
  }
  .heading1 {
    font-size: 1.8rem !important;
  transform: translateX(0);

  }
  .heading2, .heading3 {
  transform: translateX(0);

  }
  .popupContainer {
    top: 40px;
  }
  .validateEmailMsgContainer {
    padding: 20px;
    margin: 120px 0 40px;
  }
  .logoWrapper {
    width: 60px;
    height: 60px;
  }
  .logoWrapper img {
    width: auto;
  }
  .validateEmailMsgWrapper {
    width: calc(100% - 80px);
  }
  .validateEmailText {
    font-size: 1rem !important;
    line-height: 25px !important;
  }
}

@media screen and (min-width: 1024px) {
  .navWrapper {
    padding: 20px 40px;
  }

  .logo {
    width: auto;
  }

  .contentContainer {
    flex-direction: row;
  }

  .contentWrapper {
    width: 50%;
    min-height: 100vh;
  }
  .bgImg {
    width: 80%;
    min-height: 90vh;
  }

  .factsWrapper {
    margin-left: 50px;
    padding: 0;
  }

  .factsHeading {
    font-size: 1.8rem !important;
  }

  .infoText {
    font-size: 1.1rem !important;
  }

  .content {
    padding: 0;
  }

  .formContent {
    padding: 0;
  }

  .backBtn{
    left: -40px;
  }

  .heading1 {
    font-size: 2.45rem !important;
  }

  .heading2,
  .heading3 {
    font-size: 3.125rem !important;
  }

  .errorWrapper {
    display: flex;
    align-items: center;
  }

  .errorText {
    font-size: 0.95rem !important;
    font-weight: 400 !important;
    color: #f87171 !important;
  }

  .popupContainer {
    top: 90px;
   
  }

  .footer {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background-color: #22007c;
    z-index: 10;
  }

  .validateEmailMsgContainer {
    margin: 0;
  }

  .emailValidatedMsgContainer {
    padding: 0;
  }

  .welcomeImg {
    width: 100%;
  }
}

@media screen and (min-width: 1100px) {
  .backBtn{
    left: -60px;
  }
}

@media screen and (min-width: 1200px) {
  .backBtn{
    left: -80px;
  }
}
@media  screen and (max-width: 1600px){
  .formContainer{
    padding-top: 40px;
  }
}
@media  screen and (max-width: 1440px){
  .formContainer{
    padding-top: 50px;
  }
}
@media  screen and (max-width: 1366px){
  .formContainer .button {
    margin-top: 0px;
  }
  .headingWrapper .heading3{
    font-size: 2.9rem!important;
  }
  .headingWrapper{
    margin-bottom: 25px;
  }
  .formContainer{
    padding-top: 60px;
  }
  .factsBgImg{
    height: 100vh;
  }
  .popupErrorText{
    font-size: .85rem !important;
  }
  .popupError{
    width: 100%;
  }
  .headingWrapper  .heading1{
    font-size: 2.2rem!important;
  }
  .formWrapper .inputWrapper{
    margin-bottom: 17px;
  }
  .cirleIcon, .checkIcon, .dashIcon, .validationText, .notValid, .isValid{
    font-size: .9rem;
  }
  .input{
    font-size: 14px!important;
  }
}

@media  screen and (max-width: 1100px){
  .headingWrapper .heading3{
    font-size: 2.4rem!important;
    line-height: 40px!important;
  }
  .formWrapper .inputWrapper{
    margin-bottom: 10px;
  }
  .headingWrapper  .heading1{
    font-size: 2rem!important;
    line-height: 38px!important;
  }
  .cirleIcon, .checkIcon, .dashIcon, .validationText, .notValid, .isValid{
    font-size: .8rem!important;
  }
  .popupError{
    padding: 5px 15px;
  }
  .input{
    font-size: 13px!important;
  }
  .formContainer{
    padding-top: 80px;
  }
  .contentContainer{
    align-items: stretch;
  }
}
@media  screen and (max-height: 700px){
  .formContent{
    padding: 100px 0 80px;
    }
}
@media  screen and (max-height: 600px){
  .formContent{
  padding: 100px 0 70px;
  }
  .contentContainer{
    align-items: stretch;
  }

}
