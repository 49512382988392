.bar-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 160px;
    max-width: 1000px;
    align-self: center;
    padding: 0 10px
}
.progressBarLine{
    position: absolute;
    top: 90px;
}
.progress-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.progress-circle,
.progress-circle-role,
.progress-circle-vertical,
.progress-circle-journey,
.progress-circle-institution,
.progress-circle-immediateGoal,
.progress-circle-futureGoal,
.progress-circle-active{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: '#26313F';
    background-image:linear-gradient(90deg, #26313F, #26313F);
    z-index: 2;    
    position: relative;  
}

.progress-circle-active{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #37CFEF, #27C5F4);
}

.progress-circle-role{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #37CFEF, #27C5F4);
}

.progress-circle-vertical{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #37CFEF, #27C5F4);
}
.progress-circle-journey{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #49A0F5, #4E9AF5);
}
.progress-circle-institution{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #6141F5, #6332F5);
}
.progress-circle-immediateGoal{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #8811F7, #9015F7);
}
.progress-circle-futureGoal{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #C429DF, #CB28D3);
}
.progress-line,
.progress-line-role,
.progress-line-vertical,
.progress-line-journey,
.progress-line-institution,
.progress-line-immediateGoal,
.progress-line-futureGoal
.progress-line-active{
    height: 6px;
    flex-grow: 1;
    background-image:linear-gradient(90deg, #26313F, #26313F);
    z-index: 1;  
    margin: 0 -1px;
    position: relative;  
}

.progress-line-active{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #2AC8F2, #4B9DF5);
}

.progress-line-role{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #2AC8F2, #4B9DF5);
}
.progress-line-vertical{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #2AC8F2, #4B9DF5);
}
.progress-line-journey{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #4B9DF5, #6338F5);
}
.progress-line-institution{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #6239F5, #8C13F7);
}
.progress-line-immediateGoal{
    background-color: #26313F00;
    background-image:linear-gradient(90deg, #8C13F7, #C828D9);
}
.progress-line-start, .progress-line-end {
    width: auto;
    background-color: #26313F;
    height: 6px;
    align-self: center;
    position: relative;  
    z-index: 1;    
}
.progressBarLine .progress-line-start, .progressBarLine .progress-line-end {  
    flex-basis: 100%;
}

.progress-line-start {
    border-radius: 5px 0 0 5px;
    background-image: linear-gradient(90deg, #72EFDE, #33CDF0);
 }
 .progressBarLine .progress-line-start.lastLine{
    border-radius: 0 5px 5px 0;
    border: 0px solid rgba(51, 65, 85, .5);
    background-color: rgba(51, 65, 85, .5);
 }
 .progressBarLine .progress-line-start.lastLine.progress-line-active{
    background-color: #72EFDE;
    background-image: linear-gradient(90deg, #72EFDE, #33CDF0);
 }
 .progress-line-end{
     background-image:linear-gradient(90deg, #C828D9, #DD0AF3);
 }
 .progress-circle.circleBlue{
    border: 6px solid #334155;
    width: auto;
    min-width: 20px;
 }
 .progress-circle-active.circleBlue{
    width: auto;
    min-width: 20px;
 }
 .progress-circle-active.circleBlue{
    border: 0px solid #72EFDD;
    background-color: #72EFDD;
    background-image: linear-gradient(90deg, #72EFDD, #72EFDE);
 }

 .progressBarLine .progress-line-start {
    border-radius: 0;
    border: 0px solid rgba(51, 65, 85, .5);
    background-color: rgba(51, 65, 85, .5);
    background-image: linear-gradient(90deg, #334155, #334159);
  }
  .progressBarLine .progress-line-start:first-of-type{
    border-radius: 5px 0 0 5px;
 }
 .progress-line-start.progress-circle-active.circleBlue::before{
  width: 100%;
  height: 6px;
  background-color: rgba(114, 239, 221, 1);
content: "";
 }
 .progress-line-start.progress-circle-active.circleBlue:first-of-type::before{
    border-radius: 5px 0 0 5px;
 }
 .progress-line-start.next-btn-visible.circleBlue{
    width: 100%;
    height: 6px;
    background-color: rgba(114, 239, 221, 1);
  content: "";
   }
span.progress-circle.next-btn-visible.circleBlue{
  
   border: 6px solid rgba(114, 239, 221, 1);
   
   }
   div.progress-circle.next-btn-visible.circleBlue{
  
    border: 0px solid rgba(114, 239, 221, 1);
    background-image: linear-gradient(90deg, #72EFDD, #72EFDE);
    }
 .progress-circle.circleBlue svg{
    display: none;
 }
 .progress-circle-active.circleBlue svg{
    width: 10px;
    height: 10px;
 }
 .progress-circle.circleBlue.checkOn svg{
    width: 10px;
    height: 10px;
    display: inline;
}
span.progress-circle.circleBlue.checkOn{
 
    border: 0px solid rgba(114, 239, 221, 1);
  background-color: rgba(114, 239, 221, 1);
  background-image: linear-gradient(90deg, #72EFDD, #72EFDE);
}

 @media screen and (min-width: 320px) {
    .bar-container {
        padding: 0 20px;
    }
 }

 @media screen and (min-width: 600px) {
    .bar-container {
        padding: 0 30px;
    }
 }


 @media screen and (min-width: 1024px) {
    .bar-container {
        padding: 0;
    }
 }