/* Video Player Container */
.video-player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Button Container */
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  /* Individual Buttons */
  .button-container .mr-3 {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .button-container .mr-3:hover {
    background-color: #0056b3;
  }
  
  .button-container .mr-3:focus {
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.7);
  }
  
  /* Player Container */
  .video-react-player {
    width: 100%;
    max-width: 720px;
    height: auto;
    margin-bottom: 20px;
    padding-top: 0px !important;
  }
  
  /* State Display */
  .state-display {
    font-family: "Courier New", Courier, monospace;
    background-color: #2d2d2d;
    color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    max-width: 90%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  
  /* Custom Styling for Specific Components */
  .video-react-control-bar {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .video-react-play-control {
    color: #ffffff;
  }
  
  .video-react-progress-control {
    background-color: #888888;
  }
  
  .video-react-fullscreen-control {
    color: #ffffff;
  }
  
  .video-react-current-time-display,
  .video-react-duration-display {
    color: #ffffff;
  }
  