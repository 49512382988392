.inputWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  font-weight: 700;
  padding: 5px 10px;
  color: #ffffff40;
  transition: all 0.3s ease-in-out;
  cursor: text;
}

.input {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 13px 20px 10px 28px;
  border: none;
  outline: none;
  border-radius: 20px;
  background-color: #111921;
  transition: all 0.3s ease-in-out;
}

/* Focus styles */
.input:focus {
  outline: none;
  border: 0.5px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff50;
  background-color: transparent;
}

.input:not(:focus):valid {
  border: 0.5px solid #ffffff;
  background-color: transparent;
}

.input:placeholder-shown {
  border: 0.5px solid #484c50;
  background-color: #1e252c;
  padding-left: 110px; 
}

.input:focus + .label,
.input:not(:focus):valid + .label {
  transform: translateY(-33px) translateX(-3%) scale(0.8);
  color: #ffffff;
  background-color: #1e252c !important;
  background-image: none !important;
  padding: 0 5px;
}

.phoneInput::placeholder {
  font-family: 'Arial', sans-serif;
  font-size: 13px; 
  color: #B0BEC5; 
}

.input:placeholder-shown + .label {
  transform: translateY(-33px) translateX(-3%) scale(0.8);
  color: #ffffff40;
  background-color: #1e252c !important;
  background-image: none !important;
  padding: 0 5px;
}

@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

@keyframes autofill {
  0%,
  100% {
    color: #fff;
    background: transparent;
  }
}

.input:-webkit-autofill {
  -webkit-animation-delay: 1s !important;
  -webkit-animation-name: autofill !important;
  -webkit-animation-fill-mode: both !important;
}

.input:autofill {
  animation-delay: 1s !important;
  animation-name: autofill !important;
  animation-fill-mode: both !important;
}

/* Checkbox styles */
.checkboxWrapper {
  width: 90%;
  align-self: center;
  display: flex;
  align-items: flex-start;
}

.termsText,
.termsLink {
  font-size: 0.95rem;
}

.termsText {
  font-weight: 400;
}

.termsLink {
  font-weight: 600;
  text-decoration: underline;
}

.checkboxLabel {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkboxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 8px;
  background-color: none;
  border: 0.5px solid #ffffff50;
}

/* On mouse-over, add a grey background color */
.checkboxLabel:hover input ~ .checkmark {
  background-color: #72efdd50;
  border: none;
}

/* When the checkbox is checked, add a blue background */
.checkboxLabel input:checked ~ .checkmark {
  background-color: #72efdd;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxLabel input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxLabel .checkmark:after {
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #1e252c;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*  Create password page styles */
.passwordIconWrapper {
  position: absolute;
  top: 8px;
  right: 10px;
}

.eyeIcon {
  color: #a9a5a5;
  font-size: 1.4rem;
  transition: color 0.3s ease-in-out;
}

.input:focus ~ .eyeIcon {
  color: #ffffff;
}

.validationContainer {
  margin-left: 40px;
}

.validationWrapper {
  margin: 4px 0;
  display: flex;
  align-items: center;
}

.cirleIcon,
.checkIcon,
.dashIcon .validationText,
.notValid,
.isValid {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #ffffff;
}

.notValid {
  color: #f87171;
}

.isValid {
  color: #72efdd;
}

/* Creat country page styles */
.inputWrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../../assets/skipArrow.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}

.inputWrapper option {
  background-color: #1e252c;
}

.phoneInputWrapper {
  position: absolute;
  top: 0px;
  left: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  padding: 14px 0 11px 0;
}
.flag {
  width: 30px;
  height: 20px;
  border-radius: 8px;
  overflow: hidden;
}
.phoneInputWrapper span {
  color: #ffffff;
}
.phoneInput {
  padding-left: 140px;
  padding-right: 40px;
}
.divider {
  border: 0.5px solid #ffffff50;
  height: 15px;
  margin: 0 10px;
}
.input.phoneInput {
  padding-left: 110px;
}
.input.phoneInputuser {
  padding-left: 120px;
  font-family: 'Poppins', Arial, Helvetica, sans-serif!important;
  font-size: 15px!important;
  font-weight: 300;
}

.phoneInputWrapper .phoneCode {
  font-family: 'Poppins', Arial, Helvetica, sans-serif!important;
  font-size: 15px;
  font-weight: 300;
}

.phoneIconWrapper {
  position: absolute;
  top: 11px;
  right: 10px;
}

.phoneIcon {
  font-size: 1.3rem;
}

.typingWrapper {
  border: 1px solid #fef3c7;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 13px;
}

.typing {
  font-size: 0.6rem;
  color: #fef3c7;
}

.validPhone {
  color: #72efdd;
}

.invalidPhone {
  color: #f87171;
}
.signupHeading{
  transform: translateX(0);
}

.heading3style {
  background-clip: text;
  background-image: linear-gradient(to right, #72efdd, #26c5f3 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 20px;
}

.heading2style {
  font-weight: 100 !important;
}

.headingTransform {
  transform: translateX(50px);
}


/* Media Queries */
@media screen and (min-width: 600px) {
  .headingTransform {
    transform: translateX(0);
  }
}