* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
}

.landing-container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #050d15;
  color: #fff;
}

.navbar-container {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 50;
}

.navbar-container::before {
  position: absolute;
  content: "";
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #272e3450;
  backdrop-filter: blur(10px);
  width: 100%;
}

.navbar {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-self: center;
  flex-direction: column;
  z-index: 1;
  position: relative;
}

.logo {
  width: 180px;
  margin-left: 10px;
  z-index: 2
}

.navlinks-container,
.navlinks-container-close {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateX(0%);
  transition: all 0.3s ease-in-out;
}

.navlinks-container-close {
  transform: translateX(100%);
}

.navlinks-container::before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #272e3490;
  backdrop-filter: blur(20px);
}

.navlinks-wrapper {
  z-index: 2;
  margin-top: 40px;
}

.nav-links {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.nav-links a {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid #ffffff00;
  transition: border-bottom 0.3s ease-in-out;
}

.nav-links li {
  list-style-type: none;
}

.navlinks-container a:hover {
  border-bottom: 1px solid #ffffff50;
}

.btn-divider-wrapper {
  z-index: 2;
}

.btn-wrapper {
  width: 120px;
  height: 34px;
  border-radius: 20px;
  display: inline-block;
  margin: 20px 0;
  position: relative;
}

.btn-wrapper::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 34px;
  z-index: -1;
  inset: 0;
  padding: 1px;
  border-radius: 20px;
  background: linear-gradient(to right, #6200ea, #26c5f3);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;

}

.login-btn {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  position: relative;
  background-color: transparent;
}

.btn-wrapper2 {
  display: none;
}

.menu-icon-wrapper {
  position: absolute;
  right: 10px;
  margin-top: 4px;
}

.main {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  overflow-x: hidden;
}

.hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 10px;
}

.hero-bg {
  position: absolute;
  min-height: 100vh;
  top: -150px;
  right: -10px;
  z-index: 1;
}

.hero-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.img-cover {
  max-width: 50%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: calc(50% - 150px);
  margin-left: 150px;
}

.hero-img {
  max-height: 400px;
  height: auto;
  width: 100%;
  object-fit: contain;
  transform: translateX(-30px);
}

.hero-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-text {
  font-weight: 900;
  color: #ffffff80;
  text-align: center;
  margin-top: -20px;
}

.hero-text em {
  color: #ffffff;
}

.join-btn {
  width: 100%;
  max-width: 524px;
  font-weight: 800;
  padding: 12px 30px;
  color: #fff;
  font-size: .8rem;
  border: 0;
  border-radius: 30px;
  margin: 50px 0;
  background: linear-gradient(90deg, #6201ea, #26c5f3);
}

.contact-btn {
  margin: 20px 0;
}

.hero-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 40px;
}

.gradient-line {
  display: block;
  background-image: linear-gradient(90deg, #ffffff00, #ffffff90, #ffffff00);
}

.hero-footer span {
  width: 100%;
  height: 1px;

}

.hero-footer p {
  text-align: center;
  padding: 30px 0;
  font-size: .9rem;
  font-weight: 400;
}



/* Media Queries */
@media screen and (min-width: 320px) {
  .logo {
    margin-left: 20px
  }

  .menu-icon-wrapper {
    right: 20px;
  }

  .hero {
    padding: 0 20px;
  }

  .hero-bg {
    right: -20px;
  }
}

@media screen and (min-width: 450px) {
  .logo {
    margin-left: 40px
  }

  .menu-icon-wrapper {
    right: 40px;
  }

  .hero-img {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 600px) {
  .logo {
    width: 250px;
    margin-left: 40px
  }

  .menu-icon-wrapper {
    margin-top: 10px;
  }

  .hero {
    padding: 0 30px;
  }

  .hero-bg {
    right: -30px;
  }

  .join-btn,
  .hero-footer p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    flex: 1;
  }

  .logo {
    width: 200px;
    margin-left: 0;
  }

  .navlinks-container,
  .navlinks-container-close {
    width: auto;
    position: static;
    min-height: auto;
    transform: translateX(0%);
    align-items: center;
    flex-direction: row;
  }

  .navlinks-container::before {
    display: none;
  }

  .navlinks-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    flex-direction: row;
    align-items: center;
    margin-top: 0
  }

  .nav-links {
    margin: 0 5px;
    width: 18%;
  }

  .nav-links a {
    margin: 0;
    font-size: .85rem;
    color: #ffffff90;
  }

  .btn-divider-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .btn-line {
    display: inline-block;
    height: 25px;
    width: 0.5px;
    border: 0.5px solid #ffffff30;
    margin: 0px 15px 0;
  }

  .menu-icon-wrapper {
    display: none;
    width: 100%;
    justify-content: space-between;
  }

  .main {
    padding: 100px 0;
  }

  .hero {
    align-self: center;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 40px;
  }

  .hero-bg {
    right: -40px;
  }

  .hero-top {
    width: 100%;
    flex-direction: row;
    margin-top: 50px;
  }

  .hero-img {
    width: 100%;
    max-height: 400px;
  }

  .hero-text-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 40px;
  }

  .hero-text {
    text-align: right;
    font-size: 2.5rem;
    margin-top: 0;
  }

  .hero-footer p {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1160px) {
  .navbar {
    padding: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .navbar {
    padding: 20px 40px;
  }

  .logo {
    width: 270px;
  }

  .nav-links a {
    font-size: .9rem;
  }
}

@media screen and (min-width: 1300px) {
  .hero-text {
    font-size: 3rem;
  }

}

@media screen and (min-width: 1320px) {
  .nav-links {
    width: auto;
  }
}

@media screen and (min-width: 1400px) {
  .hero-text-wrapper {
    width: 60%;
    margin-right: 100px;
  }

  .hero-text {
    font-size: 3rem;
  }

}

@media screen and (min-width: 1600px) {
  .hero-top {
    max-width: 1500px;
  }


  .hero-text-wrapper {
    max-width: 600px;
    margin-right: 150px;
  }
}